import React from 'react'
import Layout from '../../components/Layout'
import CustomToolbar from '../../components/CustomToolbar'
import { Grid, Paper, Toolbar, MenuItem, Button, Box, Typography, makeStyles, Tabs, Tab} from '@material-ui/core'

import MyRecords from './MyRecords'
import PendingTasks from './PendingTasks'
import ForwardedTasks from './ForwardedTasks'
import { FaPlus, FaArrowAltCircleDown, FaArrowAltCircleUp, FaUserCircle } from 'react-icons/fa'


const useStyles = makeStyles(t => ({
    root: { flexGrow:1},
    toolbar: { 
        background: t.palette.grey[200],
        marginTop: -t.spacing(1.5)
    }, 
    menuItem: {
        border: `1px dashed ${t.palette.grey[800]}`,
        width: '100%', 
        margin: t.spacing(1),
    }
}))

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box >
            <Typography component = "div">{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

const Landing = props => {
    const classes = useStyles()
  
    
    const [tabIndex, setTabIndex] = React.useState(0);

    const handleTabChange = (event, newValue) =>  setTabIndex(newValue)
    
  return (
    
    <Layout>
      <CustomToolbar 
        title = "BIROUL MEU"
        action = {
          <Button variant = "contained" color = "primary" onClick = {() => props.history.push('/document/nou')}>
            <FaPlus/> &nbsp;&nbsp;ADAUGĂ O ÎNREGISTRARE NOUĂ
          </Button>
        }  
      />



    <Paper className={classes.root}>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        indicatorColor="secondary"
        textColor="primary"
        
      >
        <Tab label={
          <Grid container alignItems = "center">
            <FaArrowAltCircleDown style = {{ fontSize: "1.2rem", color: "red"}}/>
            &nbsp;
            SARCINI PRIMITE
          </Grid>} 
        />
        <Tab label={
          <Grid container alignItems = "center">
            <FaArrowAltCircleUp style = {{ fontSize: "1.2rem", color: "green"}}/>
            &nbsp;
            SARCINI TRANSMISE
          </Grid>} 
        />
        <Tab label={
          <Grid container alignItems = "center">
            <FaUserCircle style = {{ fontSize: "1.2rem", color: "navy"}}/>
            &nbsp;
            DOCUMENTE INIȚIATE DE MINE
          </Grid>} 
        />
            
      </Tabs>
      <TabPanel value={tabIndex} index={0}>
          <PendingTasks/>
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
          <ForwardedTasks />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
          <MyRecords/>
      </TabPanel>
    </Paper>
  


    </Layout>
    

    )
}

export default Landing