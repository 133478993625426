import React from 'react'
import { Toolbar, makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles( t => ({
    toolbar: { background: t.palette.grey[200], marginTop: -t.spacing(2), 
        display: 'flex', justifyContent: 'space-between', alignItems: 'center'},
    title: {
        letterSpacing: t.spacing(0.7), color: "NAVY", fontWeight:800, fontFamily: "Arial",
        fontSize: '1.5rem',
        // textShadow: "1px 1px 5px navy"
    }
}))

export default function CustomToolbar(props) {
  
    const classes = useStyles()
    return (
        <Toolbar className = {classes.toolbar} variant = "dense">
            <div className = {classes.title}>{props.title}</div>
            <div>{props.action}</div>
        </Toolbar>
    )
}
