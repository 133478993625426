import React, {createContext, useReducer} from "react";

const reducer = ( state, dispatch ) => ({...state, ...dispatch})

const initialState = {
    isLoggedIn: false,
    data: {},
    currentItem: null,
    user: null,
    modal: null,
    error: null
};

const Store = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <Context.Provider value={[state, dispatch]}>
            {children}
        </Context.Provider>
    )
};

export const Context = createContext(initialState);
export default Store;