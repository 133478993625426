import config from "../config"
const {namespace} = config

export const get = () => {
    let res
    let ls = localStorage.getItem(namespace)
    if ( ls ) { res = JSON.parse(ls)}
    else {res = null }
    return res
}

export const set = (item) => {
    localStorage.setItem(namespace, JSON.stringify(item))
    return true
}

export const append = item => {
    const existing = get() || {}
    if ( existing && item ) {
        localStorage.setItem(namespace, JSON.stringify({...existing, ...item}))
        return true
    } else {
        return false
    }
}

export const remove = () => {
    localStorage.removeItem(namespace)
    return true
}

export default { get, set, remove, append}