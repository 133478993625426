import { Dialog, DialogContent, Grid, IconButton, Typography, useMediaQuery } from '@material-ui/core'
import { makeStyles, useTheme, } from '@material-ui/core'
import React, {Fragment, useRef} from 'react'
import { MdClose, MdPrint } from 'react-icons/md'
import { useReactToPrint } from 'react-to-print'
import PdfViewerWrapper from './PdfViewerWrapper'


const useStyles = makeStyles( theme => ({
    dialog: {minWidth: "80vw", minHeight: "100vh"}
}))



const getExtension = file => {
    let ext = file.split(".")
    return ext[ext.length-1]
}

export default function PdfPreviewModal({data, onClose}) {
    const printRef = useRef()
    const classes = useStyles()
    const closeHandler = () => onClose()
    const theme = useTheme()
    const widthSm = useMediaQuery(theme.breakpoints.up("sm"))


    const printHandler = useReactToPrint({
        content: () => printRef.current
    })

    return (
        <Dialog open = {Boolean(data)} onClose = {closeHandler} 
        classes = {{ paper:widthSm? classes.dialog: null}}
        fullScreen = {!widthSm}
        >
            <DialogContent>
               
                <Grid container justify="space-between" alignItems="center">
                   
                    <Grid item xs ={8}>
                        <Typography variant="h6" color="primary"> {data && data.title}</Typography>
                    </Grid> 
                    <Grid item xs ={3} align="right">
                        <IconButton onClick={printHandler}>
                            <MdPrint size="2em"/>
                        </IconButton>
                        <IconButton onClick={closeHandler}>
                            <MdClose size="2em"/>
                        </IconButton>
                    </Grid> 
                    <Grid item xs = {12} align="center">
                      
                { data && data.file && getExtension(data.title).toLowerCase() === "pdf"
                // && getExtension(data.file) === "pdf" 
                && (
                    
                    <PdfViewerWrapper url = {data.file}  ref = {printRef} />
                    
                )} 
                { data 
                    && data.title 
                    && ["jpg", "JPG", "jpeg", "JPEG", "gif", "GIF", "png", "PNG", "bmp", "BMP", "svg", "SVG"].includes(getExtension(data.title)) 
                    && <img src = {data.file} style = {{height: "auto", width: "100%"}} />

                }  
                { (!data || (data && !data.file ) ) && 
                <Fragment>
               
                <Typography variant = "body2" color="error" align="right">
                    ACEST DOCUMENT NU ESTE MOMENTAN DISPONIBIL ONLINE<br/>
                    VĂ RUGĂM SĂ NE CONTACTAȚI TELEFONIC SAU PRIN EMAIL!
                </Typography>
                </Fragment>}
                
                </Grid>
               

                </Grid>
            </DialogContent>
        </Dialog>
    )
}
