import React, {useState} from 'react'
import {useHistory} from 'react-router'
import moment from 'moment'
import { Table, td, TableHead, TableRow, makeStyles, TableBody } from '@material-ui/core'
import Confirm from '../Confirm'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'

const RM_ENTRY = gql`mutation($id: ID) {
    removeEntry(id: $id) 
}`

const useStyles = makeStyles(theme => ({
    root: { padding: theme.spacing(0.5)},
    table: {
        overflow: "auto",
        border: "1px solid black",
        borderCollapse: "collapse",
        // height: "20vh",
        "&& thead tr th": {
            position: "sticky",
            top: 0
          },

        "&& th": {
                // padding: "16px",
                // paddingLeft: "15px",
                borderLeft: "1px dotted rgba(200, 209, 224, 0.6)",
                borderBottom: "1px solid #e8e8e8",
                background:theme.palette.grey[400],
                textAlign: "left",
                boxShadow: "0px 0px 0 1px #e8e8e8"
              
        },
        "&& thead tr:nth-child(2) th": { padding: 0, fontSize: "0.5rem", fontWeight:"normal", textAlign: "center" },
        "&& thead>tr>th,td, tbody>tr>td": {
            padding: theme.spacing(0.4), border: "1px dotted black", fontSize: "0.75rem", fontWeight: 500, margin: 0,color: "black"
        },
        "&& thead>tr>th": {color: "navy", textAlign: "center"},
        "&& tbody>tr:nth-child(odd)": {background: theme.palette.grey[300]},
        "&& tbody>tr:nth-child(even)": {background: theme.palette.grey[0]},
        "&& tbody>tr:hover": {background: "#EBF5FB"}
        
    }
}

))
export default function Reg(props) {
    const classes = useStyles()
    const history = useHistory()
    const [confirm, setConfirm] = useState(null)
    const [rmEntry, dispatchRmEntry] = useMutation(RM_ENTRY)

    const removeHandler = (e,i) => ev => {ev.preventDefault();setConfirm( {item: e, index:i})}
    const confirmCloseHandler = ev => {
        if ( ev ) {
            rmEntry({variables: {id: confirm.item.id}}).then( r => { 
                console.log("r:", r.data.removeEntry)
                if ( Boolean(r.data.removeEntry)  ) {
                    let data = {...props, entries: [...props.entries]}
                    data.entries.splice(confirm.index,1)
                    props.onChange(data)

                    alert("Înregistrare a fost ștearsă!")
                } else {
                    alert("Nu s-a șters, înregistrarea are workflow-uri sau atasamente?")
                }
            })
        }
        setConfirm(null)
    }
    return (
        <div align="center" className={classes.root}>
            
            <table className={classes.table} cellPadding={0} cellSpacing={0}>
                <thead>
                    <tr>
                        <th rowSpan="1" style = {{width: "5%"}}>Nr. înreg</th>
                        {/* <th colSpan="2">Data înregistrării<br />Anul 20{moment(props.start).format('YY')}</th> */}
                        <th rowSpan="1" style = {{width: "5%"}}>Data</th>
                        <th rowSpan="1" style = {{width: "15%"}}>Tip document</th>
                        <th rowSpan="1" style = {{width: "17.5%", textAlign: "left", paddingLeft:"5px"}}>Emitent</th>
                        <th rowSpan="1" style = {{width: "17.5%", textAlign: "left", paddingLeft:"5px"}}>Destinatar</th>
                        <th rowSpan="1" style = {{width: "50%", textAlign: "left", paddingLeft:"5px"}}>
                            Conținutul pe scurt al documentului
                        </th>
                    </tr>

                </thead>
                            

                <tbody >
                    {props.entries && props.entries.map((e, i) => (
                        <TableRow key={i} onClick = {()=>history.push(`/document/${e.id}`)}>
                            <td align = "center" style={{color:e.direction==="in" ? "red" : "blue"}} 
                            onContextMenu={removeHandler(e,i)}>
                                {e.nr}
                            </td>
                            <td>{moment(e.dt).format('DD.MM.YYYY')}</td>
                            <td align = "center">{e.doctype.label}</td>
                            <td>
                                {e.direction === "in" 
                                    ? ((e.contact && e.contact.name) || "...")
                                    : e.user.name
                                }
                            </td>
                            <td>
                                {e.direction === "out" 
                                    ? ((e.contact && e.contact.name) || "...")
                                    : e.lastWorkflow.nextUser.name
                                }
                            </td>
                            <td>
                                {/* {console.log(e)} */}
                                {/* {e.desc ? <div dangerouslySetInnerHTML = {{__html: e.desc}}/> : null} */}
                                {e.title ? e.title  : "---"}
                            </td>
                        </TableRow>
                    ))}
                </tbody>

            </table>
            {confirm && <Confirm title="STEGERE" message = {`Sigur doriți ștergerea numărului ${confirm.item.nr}?`} open = {confirm} onClose = {confirmCloseHandler} />}
            {/* {props.entries && (<pre>{JSON.stringify(props.entries, null, 3)}</pre>)} */}
        </div>
    )
}
