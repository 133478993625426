import React from 'react'
import { makeStyles, Paper, Toolbar, Grid} from "@material-ui/core"

const useStyles = makeStyles(theme => ({
    containingPaper: { margin: theme.spacing(1), border: '1px dotted grey', },
    innerPaper: { padding: theme.spacing(0.5), margin: theme.spacing(0.2), border: '0px solid lightblue', height: "10vh", overflow: "auto",
background: theme.palette.grey[150] },
    boxCardToolbar: { 
        background: theme.palette.grey[300], color: theme.palette.primary.dark, margin: 0, minHeight: theme.spacing(6),
        marginRight: 0, paddingRight: theme.spacing(1), paddingLeft: theme.spacing(1)
    },
}))

export const TopBoxContainer = props => {
    const classes = useStyles()
    return (
        
        <Paper className={classes.containingPaper}>

            <div className={classes.innerPaper}>
                {props.children}
            </div>
        </Paper>
    )
}