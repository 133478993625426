import React from 'react'


export default function Antet(props) {

    return (
        <div align = "left">
            <strong>{props.name}</strong>
            <br/>
            <strong>Adresa: </strong>{props.address}
            <br/>
            <strong>Site web: </strong>{props.www}  <strong>Email: </strong> {props.email}
            <br/>
            <strong>Telefon: </strong> {props.phone}, <strong>Fax: </strong> {props.fax}
            
        </div>
    )
}
