import React from 'react'
import Layout from '../../components/Layout'
import CustomToolbar from '../../components/CustomToolbar'

import { Paper, Box, Button, Toolbar } from '@material-ui/core'
import { QUERY_COMPANY } from './_graphql'
import { useQuery, useMutation } from '@apollo/client';
import { Context } from '../../utils/Store'
import ReactTable7 from '../../components/ReactTable7'
import {SelectColumnFilter, DefaultColumnFilter} from '../../components/ReactTable7/filters'
import { FaArrowAltCircleRight } from 'react-icons/fa'
import ContactModal from './ContactModal'

const composeAddress = (item) => {
    return ['str', 'nr', 'bl', 'sc', 'et', 'ap', 'zip', 'loc', 'jud'].reduce( (acc, elem) => { 
        if ( item[elem] ) { acc += elem + ": " + item[elem] + ", "}
        return acc
    }, '')
}
const composeContactInfo = (item) => {
    let res =  ['tel1', 'tel2', 'fax'].reduce( (acc,elem) => {
        if ( item[elem] ) { acc += elem + ": " + item[elem] + "<br/>"}
        return acc
    }, '')
return "<small>"+ res +"</small>"
}

const Contacts = props => {
    const [ context, dispatch ] = React.useContext(Context)
    const [ modal, setModal ] = React.useState()
    const { data, loading, error, refetch } = useQuery(QUERY_COMPANY, {
      variables: { id: context.user.companyID},
      fetchPolicy:"cache-first"
    })

    const contactHandler = data => () => setModal(data)
    
    const addPersonHandler = () => {setModal({
      companyID: context.user.companyID,
      name:'', surname:'', cnp:'', cui:'', str:'', nr:'', bl:'', sc:'', et:'', ap:'', zip:'', loc:'',
       jud:'', tel1:'', tel2:'', fax:'', email:'', obs:''
    })}

    const closeContactModalHandler = ev => {
      if (ev) refetch()
      setModal()
    }

    return (
        <Layout>
            <CustomToolbar 
              title = "AGENDA APLICAȚIEI" 
              action ={ <Button 
                  variant = 'outlined' size = "small" color = "primary"
                  onClick = {addPersonHandler}
                  >ADAUGĂ PERSOANĂ
              </Button>}  
            />

               
                <ReactTable7
                     data = { data && data.company ? data.company.contacts.map( e => ({
                         ...e, 
                         cnpcui: e.cnp || '' + e.cui || '',
                         address: composeAddress(e),
                         contactInfo: composeContactInfo(e)
                                
                    })) : []}
                     columns = { React.useMemo(() => ([
                        {
                            Header: "id",
                            accessor: "id", 
                            filter: "numeric", 
                            Filter: SelectColumnFilter,
                            style: {width: "4%", textAlign: "center"},
                            Cell: r => <div align = "center">{r.cell.row.original.id}</div>
                        },
                        { 
                            Header: "Nume/Denumire", 
                            accessor: "name",
                            style: {width: "15%", textAlign: "center"},
                            Cell: r => <div align = "center">{r.cell.row.original.name}</div>
                          },
                          { 
                            Header: "Prenume", 
                            accessor: "surname",
                            style: {width: "15%", textAlign: "center"},
                            Cell: r => <div align = "center">{r.cell.row.original.surname}</div>
                          },
                          { 
                            Header: "CNP/CUI", 
                            accessor: "cnpcui",
                            style: {width: "15%", textAlign: "center"},
                            Cell: r => <div align = "center">{r.cell.row.original.cnpcui}</div>
                          },
                          { 
                            Header: "ADRESĂ", 
                            accessor: "address",
                            style: {width: "25%", textAlign: "center"},
                            Cell: r => <div align = "center">{r.cell.row.original.address}</div>,
                            Filter: DefaultColumnFilter
                          },
                          { 
                            Header: "DATE DE CONTACT", 
                            accessor: "contactInfo",
                            style: {width: "15%", textAlign: "center"},
                            Cell: r => <div 
                                align = "center"
                                dangerouslySetInnerHTML = {{__html: r.cell.row.original.contactInfo}}
                                />,
                            Filter: DefaultColumnFilter
                          },
                          { 
                            Header: "E-MAIL", 
                            accessor: "email",
                            style: {width: "25%", textAlign: "center"},
                            Cell: r => <div align = "center">{r.cell.row.original.email}</div>,
                            Filter: DefaultColumnFilter
                          },
                          {
                            Header: () => <div>DETALII<br/>DOCUMENT</div>,
                            id: "actiuni",
                            disableSortBy: false,
                            style: {textAlign: 'center'},
                            Cell: r => <div align = "center">
                                <Button size = "small" fullWidth onClick = {contactHandler(r.cell.row.original)}>
                                    <FaArrowAltCircleRight style ={{color: 'navy', fontSize: '1.5em'}}/>
                                </Button>
                            </div>
                        }
                    ]))}
                />
            <ContactModal data = {modal && {...modal}} onClose = { closeContactModalHandler } />
        </Layout>
    )
}


export default Contacts
