import React, { useState, useEffect, useContext } from 'react'
import { Context } from '../../../utils/Store'
import {
    makeStyles, Dialog, DialogTitle, DialogContent, DialogActions,
    Menu, MenuItem, Slide, Grid, Toolbar, Button, Paper, TextField, Checkbox
} from '@material-ui/core'
import { FaTimesCircle } from 'react-icons/fa';
import clsx from 'clsx';
import CKEditor from 'ckeditor4-react';
import { QUERY_COMPANY_FOR_WORKFLOW, MUTATION_CREATE_WORKFLOW, MUTATION_CREATE_FILE } from '../_graphql'
import { useQuery, useMutation } from '@apollo/client';
import ModalUpload from '../ModalUpload'
import moment from 'moment'
import { RichCard } from '../../../components/RichCard'
import { FaPlusCircle } from 'react-icons/fa'
import uploadHelper from '../../../utils/uploadHelper'

const useStyles = makeStyles( theme => ({
    root: { flexGrow: 1 },
    paper: { minHeight: "90vh", minWidth: "90vw", },
    itemPaper: { padding: theme.spacing(1), textAlign: 'center', margin: theme.spacing(1), boxShadow: '1px 1px 3px grey' },
    titleRoot: { padding: 0 },
    toolbar: { background: theme.palette.primary.dark, color: theme.palette.primary.contrastText },
    list: {
        height: "40vh", overflow: "auto", border: "1px dashed lightblue"
    },
    fileListItem: { display: 'flex', justifyContent: 'space-between', padding: theme.spacing(1), 
        margin: theme.spacing(0.5), alignItems: 'center',
        border: '1px dashed lightgrey' }
}))

export const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddWorkflowModal(props) {

    const classes = useStyles()
    const [item, setItem] = useState()
    const [newItem, setNewItem] = useState()
    const [anchorEl, setAnchorEl] = useState(null)
    const [nextUser, setNextUser] = useState()
    const [context, dispatch] = useContext(Context)
    const [files, setFiles] = useState([])
    const [modal, setModal] = useState()
    const [createWorkflow, dispatchCreateW ] = useMutation(MUTATION_CREATE_WORKFLOW)
    const [createFile, dispatchCreateF ] = useMutation(MUTATION_CREATE_FILE)


    const { data, error, loading } = useQuery(QUERY_COMPANY_FOR_WORKFLOW, { variables: { id: context.user.companyID } })

    useEffect(() => {
        if (props && props.data && data) {
            setItem({ ...props.data })
            console.log("prrrr dtttt:", props.data)
            setNewItem({
                entryID: props.data.id,
                companyID: props.data.companyID,
                dt: new Date(),
                title: "",
                desc: "",
                currentUserID: props.data.userID,
                nextUserID: props.data.userID,
                statusID: 2,
                files: []
            })
        }
    }, [props, data])



    const closeHandler = name => () => {
        console.log("name:", name)
        props.onClose(name)
    }

    const ckHandler = (content) => setNewItem({ ...newItem, desc: content.editor.getData() })
    const titleHandler = ev => setNewItem({ ...newItem, title: ev.target.value })

    const handleNextUser = ev => () => {
        console.log("user:", ev)
        setNextUser(ev)
        setNewItem({
            ...newItem,
            nextUserID: ev.id,
            statusID: 3

        })
        setAnchorEl(false)
    }

    const statusHandler = status => () => {
        console.log("Status:", status)
        console.log("Data company statuses:", data.company.statuses)
        setNewItem({
            ...newItem,
            nextUserID: context.user.id,
            statusID: data.company.statuses.filter(el => el.title === status)[0]['id']
        })
        setNextUser()
    }

    const uploadModalHandler = ev => {
        if ( ev ) {
            setFiles([...files, ...ev])
        }
       
        setModal()
    }

    const submitHandler = async () => {
        console.log("files:", files)
        console.log("new item:", newItem)
        let wkf, uploads, err
        try {
            wkf = await createWorkflow({variables: newItem})
            uploads = await uploadHelper(files)
        } catch (error) {
            err = error
        } finally {
            if ( err ) {
                console.log("err:", err)
            } else {
                console.log("typeof :", typeof uploads)
                uploads = (typeof uploads === 'undefined' || (typeof uploads === 'object' && uploads.err )) ? [] : uploads
                console.log("typeof :", typeof uploads, "var:",uploads)
                let filesWithEntryId = uploads.map(elem => ({ ...elem, entryID: item.id, worflowId: wkf  }))
                if ( filesWithEntryId.length > 0 ) {
                    let graphs = filesWithEntryId.map( elem => createFile({ variables: elem }) )
                    Promise.all(graphs).then( 
                        r => { setFiles(r); props.onClose({...newItem, id: wkf, files: r})},
                        err => { console.log("Err:", err) }
                    )
                } else {
                    props.onClose({...newItem, id: wkf, files: []})
                }

            }
        }
        props.onClose(true)
    }

    return (
        <React.Fragment>
            {console.log("dddd:", data, item, newItem)}
            <Dialog
                open={Boolean(item)}
                onClose={closeHandler()}
                classes={{ paper: classes.paper }}
                TransitionComponent={Transition}

            >
                <DialogTitle classes={{ root: classes.titleRoot }}>
                    <Toolbar className={classes.toolbar}>
                        <Grid container justify="space-between" alignItems="center">
                            <div>  ADAUGĂ ACTIVITATE</div>
                            <FaTimesCircle style={{ fontSize: '2.7em' }} onClick={closeHandler()} />
                        </Grid>
                    </Toolbar>
                </DialogTitle>
                <DialogContent>
                    {item && newItem && (
                        <Paper className={clsx(classes.root, classes.itemPaper)}>
                            <Grid container spacing = {1}>
                                <Grid item xs={12}>
                                    {/* {JSON.stringify(item, null,4)} */}
                                    <Toolbar variant="dense" style={{ background: "lightgrey", display: 'flex', justifyContent: "space-between" }}>
                                        <div>Document nr. {item.nr} / {moment(item.dt).format('DD.MM.YYYY')}</div>
                                        <div>Stare document: {' '}
                                            <strong>
                                                {[...data.company.statuses].filter(el => el.id.toString() === newItem.statusID.toString())[0]['desc']}
                                            </strong>
                                        &nbsp; (
                                        <strong>
                                                {[...data.company.users].filter(el => el.id.toString() === newItem.nextUserID.toString())[0]['name']}
                                            </strong>
                                        )
                                    </div>
                                    </Toolbar>
                                    {/* <pre align = "left">{JSON.stringify(item, null, 4)}</pre> */}
                                    <br />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField value={newItem.title || ''} onChange={titleHandler}
                                        fullWidth size="small" variant="outlined" label="descriere pe scurt" />
                                </Grid>
                                <Grid item xs={8}>
                                    <CKEditor
                                        data={newItem.desc || ''}
                                        onChange={ckHandler}
                                        config={{ height: "100%", margin: 0, padding: 0 }}
                                    />
                                </Grid>
                            
                            <Grid item xs={4} >
                                {/* Atașamente <Button onClick = {()=> setModal({data: "bla"})}>ADD</Button>
                            { files.map( (e,i) => {
                                { console.log("file:", e)}
                                return <div key = {`fisier-${i}`}> {e.name} </div>
                            })} */}
                                <RichCard
                                    title={`FIȘIERE ATAȘATE (${item.files.length})`}
                                    action={
                                        <Button
                                            size="small"
                                            onClick={() => setModal({
                                                data: "dummy"
                                            })}
                                        >
                                            <FaPlusCircle style={{ color: "red", fontSize: '1.5em' }} />
                                        </Button>
                                    }
                                >
                            { files.map( (e,i) => {
                                return ( <div key = {`fisier-${i}`} className = {classes.fileListItem}> 
                                    <div>{e.name} ({Math.round(e.size/1024, 0)} kb) </div>
                                    <Button onClick = {() => {
                                        const f = [...files]
                                        f.splice(i,1)
                                        setFiles(f)
                                    }}>
                                        <FaTimesCircle style = {{color: 'lightGrey', size: '1.4em'}} />
                                    </Button>
                                </div>)
                            })}
                                </RichCard>
                            </Grid>
                            </Grid>
                        </Paper>
                    )}

                    {/* {item && <pre>{JSON.stringify(item,null,3)}</pre>} */}
                </DialogContent>
                <DialogActions>
                    {item && newItem && (
                        <React.Fragment>
                            Schimbă status în &nbsp;&nbsp;&nbsp;
                            <Button variant="outlined" color="primary" aria-controls="users-menu" aria-haspopup="true"
                                onClick={ev => setAnchorEl(ev.currentTarget)}
                            >
                                <Checkbox size="small" color="primary" checked={newItem.statusID === 3} />
                                    REDIRECTAT LA &nbsp;
                                    <strong style = {{color: "red"}}>
                                        {nextUser ? nextUser.name : '...'}
                                    </strong>
                            </Button>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={() => setAnchorEl(false)}
                            >
                                {data && data.company && data.company.users.map((user, index) => (
                                    <MenuItem
                                        key={`usr-${index}`}
                                        onClick={handleNextUser(user)}>
                                        {user.name}
                                    </MenuItem>
                                ))}


                            </Menu>

                            <Button variant="outlined" color="primary" onClick={statusHandler('wip')}>
                                <Checkbox size="small" color="primary" checked={newItem.statusID === "2"} />
                                ÎN LUCRU
                            </Button>
                            <Button variant="outlined" color="primary" onClick={statusHandler('end')}>
                                <Checkbox size="small" color="primary" checked={newItem.statusID === "4"} />
                            FINALIZAT
                            </Button>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Button variant="contained" color="secondary" onClick={closeHandler()}>RENUNȚĂ</Button>
                            <Button variant="contained" color="primary"
                                disabled={!(newItem.title && newItem.nextUserID)}
                                onClick = {submitHandler}
                            >
                                CONFIRMĂ
                            </Button>
                        </React.Fragment>
                    )}

                </DialogActions>

                {modal && (
                    <ModalUpload data={modal && modal.data} onClose={uploadModalHandler} />
                )}
            </Dialog>




        </React.Fragment>
    )
}
