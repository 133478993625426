import React, { useContext } from 'react';
import clsx from 'clsx';
import { withRouter } from "react-router-dom"
import {
  CssBaseline,
  useTheme,
  makeStyles,
  withStyles,
  Drawer,
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Container,
  useMediaQuery,
  Tooltip
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// import Head from '../components/Head'
import Copyright from '../components/Copyright'
import { Grid } from '@material-ui/core';
// import { useRouter } from 'next/router'
import { FaHome, FaToiletPaper, FaUsers, FaUserCog, FaPersonBooth, FaUser, FaBookOpen, FaFileSignature } from 'react-icons/fa';
import config from '../config'
import { Context } from '../utils/Store'
import ls from '../utils/ls'
import AddUserModal from '../routes/Setari/AddUserModal'
import { useMutation } from '@apollo/client'
import { MUTATE_USER } from '../routes/Setari/_graphql'
import { append } from '../utils/ls'

const drawerWidth = 100;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 20px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    // whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      //    width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),

  },
  backContainer: {
    position: 'absolute',
    top: 0, left: 0, bottom: 0, right: 0,
    backgroundImage: `url('/cioara.jpg')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    border: '5px solid red',
    padding: '20px',
    opacity: 0.07,
    zIndex: -1,
    zoom: 1
  },
  paper: {
    padding: theme.spacing(0),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  wrapText: {
    wordWrap: 'break-word'
  }
}));



const Layout = (props) => {


  const theme = useTheme()
  const width = useMediaQuery(theme.breakpoints.up('sm'))
  const [state, dispatch] = useContext(Context)
  const [userModal, setUserModal] = React.useState()
  const [updateUser, dispachUpdateUser] = useMutation(MUTATE_USER)

  // const router = useHistory()
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const userModalCloseHandler = ev => {
    updateUser({ variables: ev })
      .then(
        r => { dispatch({ user: { ...state.user, ...r['data']['user'] } }); ls.append(r.data.user); setUserModal() },
        err => { console.log("err:", err); setUserModal() }
      )

  }

  // useEffect(() => {
  //   setOpen(width)
  // }, [width])



  const logoutHandler = () => {
    ls.remove();
    dispatch({ isLoggedIn: false, data: {} })
    props.history.push('/logout')
  }


  return (
    <div className={classes.root}>

      {/* <Head title = "MONITOR OFICIAL LOCAL"/> */}
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar} variant="dense">
          <IconButton

            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Grid container justify="space-between" alignItems="center">
            <Typography variant={width ? "h4" : "caption"} color="inherit" noWrap className={classes.title}
              style={width ? { fontWeight: 800 } : null}
            >
              <span style={width ? { textShadow: '1px 1px 1px black', color: 'white' } : null}>
                SCRIBUS <small style={{ fontSize: "0.5em", fontWeight: 350, color: "yellow", textShadow: 'none' }}>
                  gestiune profesională de documente</small>
              </span>

            </Typography>
            {/* logout */}
            <Typography variant="caption" onClick={() => setUserModal(state.user)}>
              {/* {console.log("STATE: ", state)} */}
              {state && state.user && state.user.name} ({state && state.user && state.user.username})
          </Typography>

            <Button variant="text" style={{ color: 'white' }}
              onClick={logoutHandler}>LOGOUT</Button>
          </Grid>


        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}

        open={open}
      >
        {/* <Toolbar className = {classes.toolbar} style = {{background: 'darkgreen'}}> */}
        <div className={classes.toolbarIcon}>
          {/* <Button variant = "text" color = "primary" style = {{marginLeft: '1em'}} onClick = {homeHandler}>
          <FaHome style = {{fontSize: '2.2em', color: 'navy'}}/>
          </Button> */}
          <IconButton onClick={handleDrawerClose} aria-label="close side menu">
            <ChevronLeftIcon />
          </IconButton>


        </div>
        {/* </Toolbar> */}

        {/* <Divider /> */}
        <Grid container justify="space-around" alignItems="center" style={{ marginTop: '1em' }}>

        <Grid item xs={12} style={{ textAlign: 'center', opacity: '0.7', marginBottom: '2rem' }} >
            <Button onClick={() => props.history.push('/registru')}>
              <span style={{ display: "block" }}>
                <FaBookOpen style={{ fontSize: '2.7em', color: 'red' }} />
                <Typography variant="caption">REGISTRU</Typography>
              </span>
            </Button>
          </Grid>
          
          {/* {menu} */}
          <Grid item xs={12} style={{ textAlign: 'center', opacity: '0.7', marginBottom: '2rem' }}>
            <Button onClick={() => props.history.push('/biroul-meu')}>
              <span style={{ display: "block" }}>
                <FaUser style={{ fontSize: '2.7em', color: 'navy' }} /><br/>
                <Typography variant="caption">BIROUL MEU</Typography>
              </span>
            </Button>
          </Grid>

          <Grid item xs={12} style={{ textAlign: 'center', opacity: '0.7', marginBottom: '2rem' }} >
            <Button onClick={() => props.history.push('/agenda')}>
              <span style={{ display: "block" }}>
                <FaUsers style={{ fontSize: '2.7em', color: 'green' }} />
                <Typography variant="caption">AGENDĂ</Typography>
              </span>
            </Button>
          </Grid>
          {/* <Grid item xs = {12} style = {{textAlign: 'center', opacity: '0.7',  marginBottom: '2rem'}} >
                  <Button onClick = {()=> props.history.push('/rapoarte')}>  
                    <span style = {{display:"block"}}>          
                      <FaFileSignature style = {{fontSize:'2.7em',  color: 'orange'}} /> <br/>
                      <Typography variant = "caption">RAPOARTE</Typography>
                    </span>
                  </Button>
          </Grid> */}
          <Grid item xs={12} style={{ textAlign: 'center', opacity: '0.7', marginBottom: '2rem' }} >
            <Button onClick={() => props.history.push('/setari')}>
              <span style={{ display: "block" }}>
                <FaUserCog style={{ fontSize: '2.7em', color: 'brown' }} />
                <br />
                <Typography variant="caption">SETĂRI</Typography>
              </span>
            </Button>
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center', opacity: '0.7', marginBottom: '2rem' }} >
            <Button onClick={() => props.history.push('/rapoarte')}>
              <span style={{ display: "block" }}>
                <FaFileSignature style={{ fontSize: '2.7em', color: 'orange' }} /> <br />
                <Typography variant="caption">RAPOARTE</Typography>
              </span>
            </Button>
          </Grid>
        </Grid>



        {/* <Divider /> */}
        {/* <List>{secondaryListItems}</List> */}
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        {/* <Container maxWidth="xl" className={classes.container} > */}

        {props.children}



        {/* </Container> */}
        <div className={classes.backContainer} />
        <Copyright />
      </main>
      <AddUserModal data={userModal} onClose={userModalCloseHandler} />
    </div>
  );
}

export default withRouter(Layout)