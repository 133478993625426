import gql from 'graphql-tag'

export const QUERY_TIPDOC = gql(`
  query {
    doctypes {id label desc days companyID entries{id}}
  }
`)


export const MUTATE_TIPDOC = gql`
  mutation Tipdoc($id: ID, $desc: String, $label: String, $days: Int, $companyID: ID ) {
    doctype(
      id: $id, desc: $desc, label: $label, days: $days, companyID: $companyID
    ) {
      id desc label days companyID
    }
  }
`

export const REMOVE_TIPDOC = gql`
  mutation RemoveTipdoc( $id: ID ) {
    removeDoctype(id: $id) 
  }
`

export const QUERY_COMPANY = gql`
  query query($id: ID!) {
    company(id: $id) {
      id name cui regcom address city phone fax email www obs
      users {
        id companyID departmentID name username email role
      }
      
    }
  }
`

export const QUERY_COMPANY_BKP = gql`
query backup($id: ID!) {
  company(id: $id) {
    name cui regcom address city phone fax email www obs
    users { 
        name username email password role
        entries {  
            nr dt dtDoc deadline direction title desc acl  
            doctype {
                days label desc
            }
            contact {
                name surname cnp cui str nr bl sc et ap zip loc jud tel1 tel2 fax email obs
            }
            files {
                name mimetype encoding size path
            }
            
        }
    }
        
    
    }
}`

    
  

export const MUTATE_COMPANY = gql`
  mutation company(
    $id: ID
    $name: String!
    $cui: String
    $regcom: String
    $address: String
    $city: String
    $phone: String
    $fax: String
    $email: String
    $www: String
    $obs: String
    ) {
      company(id: $id, name: $name, cui: $cui, regcom: $regcom, address: $address, city: $city, phone: $phone, fax: $fax, 
        email: $email, www: $www, obs: $obs) {
          id, name, cui, regcom, address, city, phone, fax, email, www, obs
        }
    }
`


export const QUERY_COMPANIES = gql`
  query {
    companies {
    id name cui regcom address city phone fax email www obs
    users { id companyID departmentID name username email role entries{id}}
    }
  }

`

export const MUTATE_USER = gql`
mutation user( $id: ID, $companyID: ID!, $departmentID: ID, $name: String!, $username: String!, $email: String, $password: String, $role: String! ) {
  user(
    id: $id, companyID: $companyID, departmentID: $departmentID, name: $name, 
    username: $username, email: $email, password: $password, role: $role
  ) {id companyID departmentID name username email role} 
}
`

export const REMOVE_USER = gql`
  mutation user( $id: ID! ) {
    removeUser(id: $id) 
  }
`

export const REMOVE_COMPANY = gql`
  mutation remove( $id: ID! ) {
    removeCompany(id: $id) 
  }
`