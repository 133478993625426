import React, { useMemo} from 'react'
import Layout from '../../components/Layout'
import { Context } from '../../utils/Store'
import CustomToolbar from '../../components/CustomToolbar'
import EntriesList from '../../components/EntriesList'
import PaginatedList from '../../components/PaginatedList'
import FullList from '../../components/FullList';
import {Button} from "@material-ui/core"
import {FaPlus} from 'react-icons/fa'


const Registru = props => {
   
    const [state, reduce ] = React.useContext(Context)



    const documentHandler = id => () => props.history.push(`/document/${id}`)

    return ( 
       
        <Layout>
        
        {/* { data 
        ? ( */}
            <React.Fragment>
                <CustomToolbar 
                    title = "REGISTRU GENERAL"
                    action = {
                    <div>
                        <Button variant = "contained" color = "primary" onClick ={() => props.history.push('/document/nou')}>
                        <FaPlus/> &nbsp;
                        ADAUGĂ O ÎNREGISTRARE NOUĂ
                    </Button>
                    </div>}
                />

                {state.user.paginate 
                    ? <PaginatedList/>
                    : <PaginatedList/> 
                       
                }

            </React.Fragment>
        
        {/* : <div>nu sunt date</div>} */}
       
    </Layout>
      
        
    )

    
}

export default Registru