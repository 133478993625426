import React, {useState, useContext} from 'react'
import { Context } from '../../utils/Store'
import {get, append, remove} from '../../utils/ls'
import { Checkbox, FormGroup,FormControlLabel, TextField, MenuItem } from '@material-ui/core'
import { Label } from '@material-ui/icons'
const  Misc = props =>  {

    const [state, setState] = useContext(Context)

    const paginateHandler = ev => {
        append({paginate:ev.target.checked ? true : false})
        setState({...state, user: {...state.user, paginate: ev.target.checked}})
    }

    const perPageHandler = ev => {
        append({perPage:ev.target.value})
        setState({...state, user: {...state.user, perPage: ev.target.value}})
    }

    return (
        <div align = "left" style = {{padding: "10px"}}>
            <FormGroup>
                <FormControlLabel 
                    control = {<Checkbox size = "small" checked = {state.user.paginate||false} onChange = {paginateHandler} />}
                    label = {<small>Prezintă registrul ca listă paginată (pentru conexiuni lente sau un număr foarte mare de înregistrări)</small>}
                />
            </FormGroup>
            <FormGroup >
                <FormControlLabel 
                    control = {
                        <TextField variant = "outlined" size = "small" select value = {state.user.perPage|| 10} onChange = {perPageHandler} >
                            {[5,10,15,20,50,100,500,1000].map( (e,i) => (
                                <MenuItem value = {e} key = {i}>{e}</MenuItem>
                            ))}
                        </TextField>
                    }
                    label = {<small> &nbsp;număr de elemente afișate pe pagină (în cazul paginării registrului)</small>}
                />
            </FormGroup>
            
            {/* {<pre>{JSON.stringify(state, null,4)}</pre>} */}
        </div>
    )
}

export default Misc
