import React from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import EntriesList from '../../components/EntriesList'
import { Context } from '../../utils/Store'
import { CssBaseline } from '@material-ui/core'

const ForwardedTasks = props =>  {


const QUERY_REDIR_ENTRIES = gql(`
    query own($userID: ID) {
        ownEntriesOf(userID: $userID) {
            id nr dt direction acl  title
            files { id path name  mimetype size}
            doctype{  id label }
            contact{ id name surname }
            workflow {
              id title desc dt statusID status {title}
              # files { id path name originalname}
              currentUser{id name}  
              status{ id title}}
              lastWorkflow { 
                id
                title          
                status{ id title desc }
                nextUser { id name }
              }
        }
    }
`)
    const [ctx, dispatch] = React.useContext(Context)
    const { data, loading, error } = useQuery(QUERY_REDIR_ENTRIES,{variables: {userID: ctx.user.id}})

    return (
        <div>
            {loading && "SE INCARCA DATELE...."}
            <CssBaseline />
            <EntriesList data = {data && data.ownEntriesOf ? data.ownEntriesOf :  []} />
        </div>
    )
}

export default ForwardedTasks
