import  { gql } from '@apollo/client'

export const REPORT = gql`
    query report($data: ComplexQuery) {
        report (data:$data) {
            id
            company {id name cui address phone fax www email}
            entries { 
            id dt nr desc direction
            doctype{id label}
            contact{id name}
            lastWorkflow {id status{desc} nextUser { id name }}
            user {id name}
            }
            rowStart rowEnd 
        }
    }
`
