import  {gql}  from '@apollo/client'

export const QUERY_COMPANY = gql`
    query Company($id: ID!) {
        company(id: $id) {
            id name
            contacts {
                id companyID name surname cnp cui str nr bl sc et ap zip loc jud tel1 tel2 fax email obs
                entries {id}
            }
        }
    }
`

export const MUTATE_CONTACT = gql`
    mutation Contact($id: ID, $companyID:ID, $name:String, $surname:String,
        $cnp: String, $cui: String, $str: String, $nr: String, $bl: String, $sc: String, $et: String,
        $ap: String, $zip: String, $loc: String, $jud: String, $tel1: String, $tel2: String,
        $fax: String, $email: String, $obs: String) { 
            contact(
                id : $id, companyID: $companyID, name: $name, surname: $surname, cnp: $cnp, cui: $cui, str: $str, nr: $nr,  bl: $bl,
                sc: $sc, et: $et, ap: $ap, zip: $zip, loc: $loc, jud: $jud, tel1: $tel1, tel2: $tel2, fax: $fax, email: $email, obs: $obs) {
                id companyID name surname cnp cui str nr bl sc et ap zip loc jud tel1 tel2 fax email obs }
        }
`
//return boolean
export const REMOVE_CONTACT = gql`
        mutation Remove($id: [ID!]) {
            removeContact(id: $id)  
        }
`