import  { gql } from 'apollo-boost'



export const QUERY_ENTRY = gql`
    query QueryEntry($id: ID!) {
        entry(id: $id) {
            id  nr  dt  dtDoc  deadline direction  title  desc  acl 
            companyID
            contactID 
            doctypeID
            userID
            user {id name username}
            doctype { id label }
            contact {
               id name surname cnp cui str nr bl sc et ap zip loc jud tel1 tel2 fax email obs
            }
            workflow {
                id dt title desc
                status {id title desc}   
                currentUser {id name}
                nextUser {id name }
                files {id name size userID entryID workflowID}
            }
            files {id name size userID entryID workflowID}
        }
    }
  `
  export const MUTATION_ENTRY = gql`
    mutation CreateOrUpdateEntry(
        $id: ID 
        $companyID: ID
        $userID: ID
        $doctypeID: ID
        $nr: String
        $dt: String
        $deadline: String
        $dtDoc: String
        $contactID: ID
        $direction: String
        $title: String
        $desc: String
        $acl: String
        ) {
       entry (
        id: $id,
        companyID: $companyID,
        userID: $userID,
        doctypeID: $doctypeID
        nr: $nr,
        dt: $dt,
        deadline: $deadline,
        dtDoc: $dtDoc,
        contactID: $contactID,
        direction: $direction,
        title: $title,
        desc: $desc,
        acl: $acl
       ) {
           id 
       }
   }
`;

export const MUTATION_CONTACT = gql`
    mutation CreateOrUpdateContact( $id: ID  $companyID: ID $name: String $surname: String $cnp: String $cui: String $str: String $nr: String $bl: String $sc: String $et: String $ap: String $zip: String $loc: String $jud: String $tel1: String $tel2: String $fax: String $email: String $obs: String) {
        contact (
            id: $id, companyID: $companyID, name: $name, surname: $surname, cnp: $cnp, cui: $cui, str: $str, nr: $nr, bl: $bl, sc: $sc, et: $et, ap: $ap, zip: $zip, loc: $loc,  jud: $jud, tel1: $tel1, tel2: $tel2, fax: $fax,  email: $email, obs: $obs) {
                id name surname cnp cui str nr bl sc et ap zip loc jud tel1 tel2 fax email obs
            }
    }        
`;

export const MUTATION_CREATE_FILE = gql`
    mutation createFile( 
    $id: ID   $workflowID: ID  $entryID: ID $name: String  $mimetype: String $userID: ID 
    $encoding: String  $size: Int
    $path: String){
        createFile( id:$id, workflowID:$workflowID, entryID: $entryID, userID: $userID,
        name:$name, mimetype:$mimetype, encoding:$encoding, size:$size,
        path:$path) 
    }
`

export const MUTATION_REMOVE_FILE = gql`
    mutation removeFile( $id:ID!)  {
        removeFile( id:$id ) 
}`

export const QUERY_DOCTYPES = gql`
     query {
        doctypes {
            id label days
        }
    }
`

export const QUERY_USERS = gql`
    query {
        users {
            id name username
        }
    }
`

export const QUERY_COMPANY_FOR_WORKFLOW = gql`
    query Company($id: ID!){
        company(id: $id) {
            users { id name username}
            statuses { id title desc }
        }
    }
`

export const MUTATION_CREATE_WORKFLOW = gql`
    mutation WorkflowCreate( 
        $entryID: ID!
        $companyID: ID!
        $dt: String
        $title: String
        $desc: String
        $currentUserID: ID
        $nextUserID: ID
        $statusID: ID
        ) {
            workflow(
                entryID: $entryID,
                companyID: $companyID,
                dt: $dt,
                title: $title,
                desc: $desc,
                currentUserID: $currentUserID,
                nextUserID: $nextUserID,
                statusID: $statusID
            )
        }
`


