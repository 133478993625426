import { Grid, CircularProgress, IconButton, Toolbar, LinearProgress, Container } from '@material-ui/core';
import {Alert} from "@material-ui/lab"
import { makeStyles } from '@material-ui/core';
import React, { useState, useRef, useEffect, Fragment } from 'react'
import { FaChevronCircleDown,  FaChevronCircleUp } from 'react-icons/fa';
import { Document, pdfjs, Page } from 'react-pdf'



pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles(theme=>({
    toolbar: { background: theme.palette.grey[100]}
}))

const PdfViewerWrapper = React.forwardRef(({url}, printRef) => {
    const classes = useStyles()
    const [pageNr, setPageNr] = useState(1)
    const [pageCnt, setPageCnt] = useState(1)
    const [err, setErr] = useState(null)
    const [width, setWidth] = useState(0)
    const [loading, setLoading] = useState(false)
    const parentRef = useRef()

    useEffect(() => {
        if (parentRef) { setWidth(parentRef.current.clientWidth) }
    }, [parentRef])

    const togglePage = direction => () => {
        
            let p = (direction === "dec" ) ? pageNr+1 : pageNr-1
            if (p>0 && p<=pageCnt) {
            setPageNr(p)
             }
        
    }

    return (
        <div ref={parentRef} style={{ width: "100%", overflow: "hidden", border: "1px solid lightgrey", borderRadius: "10px"}}>

            {err && (<Alert severity="error">{err}</Alert>)}
            {console.log("loading? ", loading)}
            {(loading && loading.total > loading.loaded) && <LinearProgress />}
            {url && (
                <Grid container>
                    <Grid item xs = {12} >
                    {pageCnt > 1 && (
                        <Toolbar variant = "dense" className={classes.toolbar}>
                            <Grid container justify="space-between" alignItems = "center">
                                <Grid item xs = {8} sm={10}>
                                    &nbsp;
                                    Pagina {pageNr} din {pageCnt}
                                </Grid>
                                <Grid item xs = {4} sm={2} style = {{display:"flex"}} >
                                    <IconButton size="medium"  onClick = {togglePage('inc')}>
                                        <FaChevronCircleUp color="navy"/>
                                    </IconButton>
                                    <IconButton size="medium" onClick = {togglePage('dec')}>
                                        <FaChevronCircleDown color="navy"/>
                                    </IconButton>

                                </Grid>
                                
                            </Grid>
                        </Toolbar>
                    )}
                    </Grid>
                    <Grid item xs = {12} style = {{height: "85vh", overflow: "auto"}}>
                    <Container ref = {printRef}>

                   
                    <Document
                        file={url}
                        
                        onLoadSuccess={ev => {
                            setPageCnt(ev._pdfInfo.numPages)
                            setLoading(false)
                        }}
                        onLoadError={ev => setErr(ev.toString())}
                        onLoadProgress={ev => setLoading(ev)}

                    >
                        <Page 
                            pageNumber={pageNr} width={width} 
                            onLoadProgress={ev => setLoading(ev)}
                            onLoadSuccess = {()=>setLoading(false)}
                        />

                    </Document>
                    </Container>
                    </Grid>
                </Grid>
            )}

            
        </div>
    )
})

export default PdfViewerWrapper
