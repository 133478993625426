import React, { useState } from "react"
import { Grid, TextField, Button, makeStyles } from '@material-ui/core'
import FunkyInput from "../../components/FunkyInput"
import Autocomplete from '@material-ui/lab/Autocomplete';
import {gql} from 'apollo-boost'
import { useQuery } from '@apollo/client'
import { FaPlus, FaPlusCircle } from "react-icons/fa";
import ContactModal from './ContactModal'

const useStyles = makeStyles(theme => ({
    AutocompleteRoot: {padding: "0:important!"}
}))
export const LOOKUP_NAME = gql(`
    query contactLookup($text:String!) {
        contactLookup(text:$text) {
            id nr name surname jud loc str nr bl sc et ap tel1 tel2 fax email obs
        }
     }`)




const fields = [
    { value: "surname", label: "prenume (pf)", xs: 12, sm: 2 },
    { value: "cnp", label: "CNP", xs: 12, sm: 2 },
    { value: "cui", label: "CUI", xs: 12, sm: 2 },
    { value: "str", label: "strada", xs: 12, sm: 2 },
    { value: "nr", label: "nr.", xs: 12, sm: 1 },
    { value: "bl", label: "bloc", xs: 12, sm: 1 },
    { value: "sc", label: "scara", xs: 12, sm: 1 },
    { value: "et", label: "etaj", xs: 12, sm: 1 },
    { value: "ap", label: "ap.", xs: 12, sm: 1 },
    { value: "loc", label: "localitate", xs: 12, sm: 2 },
    { value: "jud", label: "județ", xs: 12, sm: 1 },
    { value: "zip", label: "c.p.", xs: 12, sm: 1 },
    { value: "tel1", label: "telefon 1", xs: 12, sm: 2 },
    { value: "tel2", label: "telefon 2", xs: 12, sm: 2 },
    { value: "email", label: "email", xs: 12, sm: 2 },
    { value: "obs", label: "observații", xs: 12, sm: 10 }
]



const Contact = props => {
    const classes = useStyles()
    const [options, setOptions] = useState([])
    const [contact, setContact] = useState({name: ''})
    const [ text, setText ] = useState('')
    const {error, loading, data } = useQuery(LOOKUP_NAME, { variables: {text: text} })
    const [ modal, setModal ] = useState(false)
   

    React.useEffect(() => {
         { if ( props.data) setOptions([{...props.data}]); setContact(props.data); }
    }, [])

    // ql effect
    React.useEffect(() => {
        if(data && data.contactLookup.length > 0 ) {
            setOptions(data.contactLookup)
        } 
    }, [data])
    
    const typingHandler = (ev,value)  => {   setText(value);  }
    
    const selectHandler = (ev,value)  => {
        ev.preventDefault()
        setContact(value || {})
        submitToParentComponent(value)
    }


    const submitToParentComponent = item => props.onChange(item) 

    const changeOthers = name => ev => {
        setContact({...contact, [name]: ev.target.value})
        submitToParentComponent({...contact, [name]: ev.target.value})
    }

    

    const closeModalHandler = ev => {
        console.log("modal close ev:", ev)
        if (!ev) { 
            setModal(false) 
        } else {
            setOptions([ev])
            setContact({...ev})
            setModal(false)
            submitToParentComponent({...ev})
        }
    }

    return (
        
        <Grid container justify="flex-start" alignItems="center" spacing={1}>
            <Grid item xs = {12} sm = {3}>
                <Autocomplete
                style = {{zIndex: '-1'}}
                disabled = {props.disabled}
                size = "small"
                id="autocomplete-combo"
                options={options}
                getOptionLabel={(option) => option.name || ''}
                onChange={(ev, val) => selectHandler(ev,val)}
                value={ contact }
                fullWidth
                onInputChange={(ev, newInputValue) =>typingHandler(ev, newInputValue) }
                // style={{ width: 300 }}
                renderInput={params => (
                    <FunkyInput 
                        {...params} 
                        label="Nume/firma"  
                        variant="outlined" 
                        
                        InputProps={{
                            ...params.InputProps,   
                            startAdornment: (
                              <React.Fragment>
                                 {!text && 
                                    <FaPlusCircle 
                                        style = {{paddingLeft: "5px", fontSize:"1.9em", color: "red"}} 
                                        onClick = {() => setModal({name: text})}
                                    /> }
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                    />
                )}

                noOptionsText = {
                    <div align = "center">
                        <small style = {{color: "red"}}>persona nu există, </small><br/>
                        <Button onMouseDown = {() => setModal({name: text})}>ADĂUGAȚI-O</Button>
                    </div>
            }
            />
            </Grid>

            {fields.map((e, i) => {

                return (
                    <Grid item xs={e.xs} sm={e.sm} key={`input-${i}`}>
                        <FunkyInput
                            label={e.label}
                            placeholder={e.label}
                            value={contact && contact[e.value] || ''}
                            onChange={changeOthers(e.value)}
                            // disabled = {props.disabled}
                        />
                    </Grid>
                )
            })}


            {/* <React.RootRef rootRef = {contactRef}> */}
                <ContactModal open = {Boolean(modal)} data = {{...contact} } onClose = {closeModalHandler}/>
            {/* </React.RootRef> */}
            
        </Grid>
        
    )
}


export default Contact