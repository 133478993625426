import fetch  from 'isomorphic-unfetch'
import config from "../config"
import ls from '../utils/ls'

 const uploadHelper = async payload => {
    let res

    if ( !payload ) { 
        return new Error({ok: false, msg: "no files submited"})
    } else {
        const formData = new FormData()
        formData.append("myfiles", payload)
        payload.map( (file, index) => formData.append( file.name, file))

  
        try {
            res = await fetch( config.api + '/upload', {
                method: 'POST',
                headers: {
                    "authorization": "Bearer "+ ls.get().token
                },
                body: formData              
            })
            res = await res.json()
        } catch (error) {
            res = {err: error} 
        } finally {
            return res
        }
    }
}

export default uploadHelper