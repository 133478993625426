import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core"

const Confirm = ({title, message, yesButton, noButton, open, onClose}) => {
    yesButton = yesButton || "confirmă"
    noButton = noButton || "renunță"
    title = title || "CONFIRMARE"
    message = message || "sunteți sigur?"

    return (
        <Dialog open = {Boolean(open)} onClose = {()=>onClose()}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{message}</DialogContent>
            <DialogActions>
                <Button variant = "contained" color = "primary" onClick = {()=>onClose(true)}>{yesButton}</Button>
                <Button variant = "contained" color = "secondary" onClick ={()=>onClose()}>{noButton}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default Confirm