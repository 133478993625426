import React, {useState, useEffect} from 'react'
import { Card, makeStyles, ListItem, ListItemText, ListItemSecondaryAction, Button, List, Divider, ListItemIcon, Grid, Chip, Drawer, Snackbar} from '@material-ui/core'
import moment from 'moment'
import { FaArrowRight, FaChevronCircleRight, FaChevronRight, FaArrowCircleRight, FaPlay } from 'react-icons/fa'
import { Alert } from '@material-ui/lab'

const useStyles = makeStyles(theme => ({
    root: { flexGrow: 1, fontSize: '0.8rem'}, 
    card: { padding: theme.spacing(0.5)},
    listitem: {border: 'none', background: theme.palette.grey[200], margin: theme.spacing(0.3),marginBottom: "10px", padding: '0 0 0.3em 0' },
    listitemtitle: {background: theme.palette.grey[300], fontWeight: 600, textAlign:"center", minHeight:'2em', 
        display: "flex", justifyContent: "space-between", alignItems: "center", padding: '5px', color: "navy"
    },
    listitemcontent: {padding: theme.spacing(1), background:"#ffffff", border:"1px dotted grey", borderTop: "none"},
    title: { padding: theme.spacing(1), margin: 0, background: theme.palette.grey[100]}
    
    
}))



const WorkItem = props => {
    const classes = useStyles()

    return (
    <ListItem className = {classes.listitem} >

        <div style = {{width: '100%'}}>
            <div className = {classes.listitemtitle}>
                <div>{props.status.desc}</div>
                <div>{moment(props.dt).format('DD.MM.YYYY - HH:MM:ss')}</div>
            </div>
            <Grid container justify = "space-between" alignItems= "center" className = {classes.listitemcontent}>
                
                <Grid item xs = {5} align = "left">
                    <div >{props.currentUser ? props.currentUser.name : ""}</div>
                </Grid>
                <Grid item s = {1} align = "center">
                    <FaPlay style = {{color: '#2196f333', fontSize: "1rem", marginTop: "1px"}}/>
                </Grid>
                <Grid item xs = {5} align = "right">
                    <div> {props.nextUser ? props.nextUser.name : "" }  </div>
                </Grid>
                <Grid item xs = {12}>
                    <div className = {classes.title}>
                    {props.title}
                    </div>
                </Grid>

                
              
            </Grid>
        
            </div>

    </ListItem>
)}

const WorkflowsList = props => {
    const classes = useStyles()

    const [data, setData] = useState()

    useEffect(() => {
        setData(props.workflow)
    }, [props])



    return (
       <div className = {classes.root}>
           { data ? (
               <List style = {{marginTop:"-10px"}}>
                   
                   {data.map( (e,i) => (
                       <span  key = {`wkf-${i}`}>
                            <WorkItem {...e} />
                            {/* <Divider/> */}
                        </span>
                   ))}
                </List>
           ) : <div> nu sunt date</div>
            }
     
        </div>
    )
}
           

export default WorkflowsList