import  { gql } from '@apollo/client'

export const PAGINATED_REPORT = gql`
    query report($data: ComplexQuery) {
        report (data:$data) {
            id
            company {id name cui address phone fax www email}
            entries { 
            id dt nr desc direction title
            doctype{id label}
            contact{id name}
            lastWorkflow {id status{desc} nextUser { id name }}
            user {id name}
            }
            rowStart rowEnd 
        }
    }
`

export const CONTACT_LOOKUP = gql`
    query contactLookup($text:String!) {
        contactLookup(text:$text) {
            id name surname
        }
     }`


export const DOCTYPES = gql`
    query doctypes {
        doctypes { id label desc}
    }
`