import React, { Fragment } from 'react';
import {
  useTable,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from 'react-table';
// import { Table, Row, Col, Button, Input, CustomInput } from 'reactstrap';
import { Table, TableHead, TableBody, TableRow, TableCell, TableFooter, TextField, Button, makeStyles, Card, CardContent, CardActions, CardHeader } from '@material-ui/core';
import { Filter, DefaultColumnFilter } from './filters';

const useStyles = makeStyles(theme => ({
  table: {
    padding: theme.spacing(1),
    width: "100%",
    maxHeight: '70vh',
    "&& tbody": {
      // background: "lightgrey",
      padding: 0, margin: 0,

    },
    "&& thead>tr>th": { border: "1px dotted lightgrey", padding: theme.spacing(1) },
    "&& tbody>tr:nth-child(odd)": { backgroundColor: "white" },
    "&& tbody>tr:nth-child(even)": { backgroundColor: "rgb(0 0 0 / 0.04)" },
    "&& tbody>tr:hover": { backgroundColor: "rgb(0 0 150 / 0.1)" },
    "&& tbody>tr>td": { padding: theme.spacing(0.7) }

  },
  footer: {
    position: "fixed",
    left: 0,
    bottom: "10px",
    width: "100%",
    backgroundColor: "white",
    color: "white",
    textAlign: "center"
  }
}))

const ReactTable7 = ({ columns, data, renderRowSubComponent }) => {

  const classes = useStyles()

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    visibleColumns,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );




  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : '';
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  return (
    <Card>

      <CardHeader title={
        <Table bordered="true" hover="true" {...getTableProps()} classes={{ root: classes.table }}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  console.log("col:", column)
                  return (
                 
                  <TableCell {...column.getHeaderProps()} style={{...column.style, width: 100*column.width/100}}>
                    <div {...column.getSortByToggleProps()}>
                      <small>{column.render('Header')}</small>
                      {generateSortingIndicator(column)}
                    </div>
                    <Filter column={column} />
                  </TableCell>
                )})}
              </TableRow>
            ))}
          </TableHead>
        </Table>
      }>


      </CardHeader>
      <CardContent style={{ height: "60vh", overflow: "auto" }}>
        <Table>
          <TableBody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row)
              console.log("row:",row)
              return (
                <Fragment key={row.getRowProps().key}>
                  <TableRow>
                    {row.cells.map((cell) => {
                      // console.log("cell:", cell)
                      return (
                        <TableCell {...cell.getCellProps()} 
                          // style ={{width: 100*cell.width/100}}
                        >
                            {cell.render('Cell')}</TableCell>
                      );
                    })}
                  </TableRow>
                  {row.isExpanded && (
                    <TableRow>
                      <TableCell colSpan={visibleColumns.length}>
                        {renderRowSubComponent(row)}
                      </TableCell>
                    </TableRow>
                  )}
                </Fragment>
              );
            })}
          </TableBody>
        </Table>
      </CardContent>
      <CardActions>
        <Table className={classes.footer}>

          <TableFooter>
            <TableRow>
              <TableCell colSpan={2}>
                <Button
                  color='primary'
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  {'<<'}
                </Button>
                <Button
                  color='primary'
                  onClick={previousPage}
                  disabled={!canPreviousPage}
                >
                  {'<'}
                </Button>
              </TableCell>
              <TableCell style={{ marginTop: 7 }}>
                Pagina {' '}
                <strong>
                  {pageIndex + 1} din {pageOptions.length}
                </strong>
              </TableCell>
              <TableCell>

                <TextField
                  variant="outlined"
                  label=" pagina"
                  type='number'
                  min={1}
                  style={{ width: 70 }}
                  size="small"
                  max={pageOptions.length}
                  defaultValue={pageIndex + 1}
                  onChange={onChangeInInput}
                />
              </TableCell>
              <TableCell>

                <TextField
                  select
                  id="opts-select"
                  label="per pag"
                  value={pageSize}
                  onChange={onChangeInSelect}
                  variant="outlined"
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                >

                  {[5, 10, 20, 30, 40, 50, 100].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </TextField>
              </TableCell>
              <TableCell colSpan={4}>
                <Button color='primary' onClick={nextPage} disabled={!canNextPage}>
                  {'>'}
                </Button>
                <Button
                  color='primary'
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  {'>>'}
                </Button>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </CardActions>
    </Card>
  )
};

export default ReactTable7;