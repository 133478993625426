import { Button, makeStyles } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React, {useContext} from 'react'
import { useLazyQuery } from '@apollo/client'
import { QUERY_COMPANY_BKP } from '../_graphql'
import { Context } from '../../../utils/Store'
import download from "downloadjs"

const useStyles = makeStyles( theme => ({
    main: {
         height: "50vh", overflow: "auto", border: "1px solid grey", 
         margin: theme.spacing(3)
    }
}))

export default function Backup() {
    const [context, dispatchContext] = useContext(Context)
    const classes = useStyles() 
    const [startBackupQuery, {called, loading, data, error}] = useLazyQuery(QUERY_COMPANY_BKP)

    const backupHandler = () => {
        startBackupQuery({variables: {id: context.user.id}})
    }

    const donwlodHandler = () => {
        download(JSON.stringify(data), "becap.json", {mineType: "application/json"})
    }

    return (
        <div className={classes.main}>
            
            <Alert severity="info">
                SE VA EFECTUA O COPIE DE SIGURANTA COMPLETA A BAZEI DE DATE INTR-UN FISIER. 
                <br/><br/>  
                Ulterior, aveți posibilitatea de a restaura datele (mai puțin fișiere încărcate)
            </Alert>
            {(!called)
            && (
                <div align ="center" style = {{height: "30vh", display: "flex", alignItems: "center", justifyContent: "center"}}>
                <Button variant = "contained" color = "secondary" size = "large" onClick = {backupHandler}>
                    <h1>FĂ O COPIE DE SIGURANȚĂ<br/> ACUM !</h1>
                </Button>
            </div>
            )}
            {loading && <Alert>Se genereaza copia de siguranta... asteptati</Alert>}
            {data && (<div>
                {donwlodHandler()}
                <h1>GATA!</h1>
                </div>)}
        

            
        </div>
    )
}
