import React from 'react'
import { Context } from '../../utils/Store'
import { useQuery, useMutation } from '@apollo/client'
import { QUERY_COMPANY, MUTATE_COMPANY, MUTATE_USER, REMOVE_USER } from './_graphql'
import { Grid, Typography, Button, Toolbar, List, ListItem, Divider, makeStyles, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import { FaTimes, FaEdit } from 'react-icons/fa'
import FunkyInput from '../../components/FunkyInput'
import AddUserModal from './AddUserModal'


const useStyles = makeStyles(t => ({
    toolbar: { background: t.palette.grey[200] }
}))

const fields = [
    { name: 'name', label: "denumire", xs: 12, sm: 12 },
    { name: 'address', label: "adresă", xs: 9, sm: 9 },
    { name: 'city', label: "localitate", xs: 3, sm: 3 },
    { name: 'cui', label: "cui/cif", xs: 6, sm: 6 },
    { name: 'regcom', label: "nr.Reg.Com.", xs: 6, sm: 6 },
    { name: 'phone', label: "telefon", xs: 12, sm: 3 },
    { name: 'fax', label: "fax", xs: 12, sm: 3 },
    { name: 'email', label: "email", xs: 12, sm: 6 },
    { name: 'www', label: "site web", xs: 12, sm: 4 },
    { name: 'obs', label: "observații", xs: 12, sm: 8 },
]

const Company = props => {
    const classes = useStyles()
    const [item, setItem] = React.useState()
    const [touched, setTouched] = React.useState(false)
    const [context, dispatch] = React.useContext(Context)
    const [ userModal, setUserModal ] = React.useState()
    const [ removeUserModal, setRemoveUserModal ] = React.useState()
    const [ err, setErr ] = React.useState()
    const { data, loading, error, refetch } = useQuery(QUERY_COMPANY, { variables: { id: context.user.companyID } })
    const [updateCompany, dispatchMutation] = useMutation(MUTATE_COMPANY)
    const [ mutateUser, dispatchMUser ] = useMutation(MUTATE_USER)
    const [ removeUser, dispatchRemoveMUser ] = useMutation(REMOVE_USER)

    React.useEffect(() => {
        if (data) {
            setItem(data.company)
        }
    }, [data])

    const inputHandler = name => ev => { setItem({ ...item, [name]: ev.target.value }); setTouched(true) }
    const submitHandler = () => {
        updateCompany({ variables: { ...item } }).then(
            r => {
                setTouched(false)
            },
            err => { console.log("err:", err) }
        )
    }


    const editUserModal = ev => () => { console.log("ev:", ev);setUserModal(ev) }
    const userModalCloseHandler = async ev => {
        // console.log("Ev:", ev)
        let promise;
       if ( !ev ) { setUserModal() } else {
            try {
                promise = await mutateUser({variables: ev})
            } catch (error) {
                promise = {err: error}
            } finally {
                if ( promise.err ) {
                    setErr(promise.err)
                    setUserModal()
                } else {
                    refetch()
                    setUserModal()
                }
            }
       }
    }

    const addUserHandler = () => {
            console.log("ITEM:", item)
        setUserModal({
            companyID: item.id,
            role: "user"
        })
    }

    const removeUserHandler = async () => {
        let promise;
        try {
            promise = await removeUser({variables: {id: removeUserModal.id }})
        } catch (error) {
            promise = {error:error}            
        } finally {
            if (promise.error) {
                alert("nu s-a putut efectua operațiunea de ștergere")
            } else {
                refetch()
                setRemoveUserModal()
            }
        }
    }

    return (
        <div>
            {loading && <div>SE INCARCA...</div>}
            {error && <pre>{JSON.stringify(error, null, 4)}</pre>}
            {err && <pre>{JSON.stringify(err, null, 4)}</pre>}
            {item && (
                <React.Fragment>
                    <Toolbar variant="dense" className={classes.toolbar} >
                        <Grid container justify="space-between" alignItems="center">
                            <Typography variant="h6" color="primary">DATE COMPANIE</Typography>
                            <Button
                                color="secondary"
                                onClick={submitHandler}
                                disabled={!touched}
                            >
                                CONFIRMĂ MODIFICĂRILE
                            </Button>
                        </Grid>
                    </Toolbar>
                    <hr />
                    {item && (
                        <Grid container spacing={1}>
                            {fields.map((elem, index) => (
                                <Grid item xs={elem.xs} sm={elem.sm} key={`input-${index}`}>
                                    <FunkyInput
                                        name={elem.name}
                                        label={elem.label}
                                        value={item[elem.name]}
                                        onChange={inputHandler(elem.name)} />
                                </Grid>
                            ))}
                        </Grid>
                    )}
                    {context.user.role === "admin" && (
                        <div>
                          
                            <Toolbar variant="dense" className={classes.toolbar} >
                                <Grid container justify="space-between" alignItems="center">
                                    <Typography variant="h6" color="primary">UTILIZATORII COMPANIEI</Typography>
                                    <Button variant = "contained" color = "primary" onClick = {addUserHandler}>ADAUGA UTILIZATOR</Button>
                                </Grid>
                            </Toolbar>

                            <ListItem>
                                <Grid container justify="flex-start" style={{ borderBottom: "1px dashed lightgrey" }}>
                                    <Grid item xs={3}><Typography variant="caption" color="primary"><strong>&nbsp;NUME</strong></Typography></Grid>
                                    <Grid item xs={2}><Typography variant="caption" color="primary"><strong>USERNAME</strong></Typography></Grid>
                                    <Grid item xs={3}><Typography variant="caption" color="primary"><strong>EMAIL</strong></Typography></Grid>
                                    <Grid item xs={2}><Typography variant="caption" color="primary"><strong>ROLE</strong></Typography></Grid>
                                </Grid>
                            </ListItem>
                            {/* <Divider /> */}
                            <List component="nav" style={{ width: '100%', overflow: "auto", height: "25vh" }}>
                                {item && item.users.map((elem, index) => (
                                    <span key={`u-${index}`}>
                                        <ListItem button component="a">
                                            <Grid container justify="flex-start">
                                                <Grid item xs={3}><small>{elem.name}</small></Grid>
                                                <Grid item xs={2}><small>{elem.username}</small></Grid>
                                                <Grid item xs={3}><small>{elem.email}</small></Grid>
                                                <Grid item xs={1}><small>{elem.role}</small></Grid>
                                                <Grid item xs={3} align="right">
                                                    <Button
                                                        disabled={elem.entries && elem.entries.length > 0}
                                                        color={elem.entries && elem.entries.length > 0 ? "default" : "secondary"}
                                                        onClick={() => setRemoveUserModal({ ...elem, index: index })}
                                                    >
                                                        <FaTimes />
                                                    </Button>
                                                    <Button>
                                                        <FaEdit style={{ color: "green" }} onClick={editUserModal({ ...elem, index: index })} />
                                                    </Button>
                                                </Grid>
                                            </Grid>

                                        </ListItem>
                                        <Divider />
                                    </span>
                                ))}
                            </List>
                        </div>

                    )}
                </React.Fragment>
            )}
            
            {userModal && (
                <AddUserModal data = {userModal} onClose = {userModalCloseHandler} />

            )}
            <Dialog open = {Boolean(removeUserModal)}>
                    <DialogTitle>CONFIRMARE ȘTERGERE</DialogTitle>
                    <DialogContent>
                        Se va șterge definitiv utilizatorul<br/>
                        <strong>
                            {removeUserModal && removeUserModal.name} !!
                        </strong>
                    </DialogContent>
                    <DialogActions>
                        <Button variant = "contained" color ="primary" onClick = {removeUserHandler}>CONFIRMĂ</Button>
                        <Button variant = "contained" color ="secondary" onClick = {()=>setRemoveUserModal()}>RENUNȚĂ</Button>
                    </DialogActions>
                        
            </Dialog>
        </div>
    )
}

export default Company
