import React, { useState, useEffect, useRef } from 'react'

import { Dialog, DialogTitle, DialogContent, DialogActions, Slide,
    makeStyles, Button, Grid,  ListItem, ListItemText, ListItemSecondaryAction, 
    Divider, List, Toolbar, AppBar } from '@material-ui/core'
import { DropzoneArea } from 'material-ui-dropzone';
import { FaTimes, FaTimesCircle } from 'react-icons/fa';




export const Transition = React.forwardRef((props, ref) => {
    return <Slide direction = "up" ref={ref} {...props} />;
  });

const useStyles = makeStyles(theme => ({
    paper: { minHeight: "90vh", minWidth: "70vw" },
    titleRoot: { padding: 0 },
    toolbar: { background: theme.palette.primary.dark, color: theme.palette.primary.contrastText }, 
    list: {
        height: "40vh", overflow: "auto", border: "1px dashed lightblue"
    }
}))

const ModalUpload = props => {

    const Drop = useRef()


    const classes = useStyles()
    const [item, setItem] = useState()
    const [files, setFiles] = useState()

    useEffect(() => {
        if (props && props.data) {
            setItem(props.data)
        }
    }, [])

    const closeHandler = (files) => ev => {
        if (!files) {
            props.onClose()
        } else {
            props.onClose(files)
        }
    }

    const changeHandler = files => {
        console.log(files)
        setFiles(files)
    }

    
    
    const removeFileHandler = i => () => {
        console.log("i", i)
        let f = [...files]
        f.splice(i,1)
        setFiles(f)
    }



    return (
        <Dialog
            open={Boolean(item)}
            onClose={closeHandler()}
            classes={{ paper: classes.paper }}
            // ref={ref}
            TransitionComponent={Transition}
            // keepMounted

        >
            <DialogTitle classes = {{root: classes.titleRoot}}>
                <Toolbar className = {classes.toolbar}>
                    <Grid container justify = "space-between" alignItems = "center">
                        <div>  ÎNCĂRCARE ATAȘAMENTE</div>
                        <FaTimesCircle style = {{fontSize: '2.7em'}} onClick = {closeHandler()}/>
                    </Grid>
                  
                </Toolbar>
               
            </DialogTitle>
            <DialogContent>
                <DropzoneArea
                    ref = {Drop}
                    acceptedFiles={['image/*', 'video/*', 'application/*', 'application/msword']}
                    onChange={changeHandler}
                    showPreviews = {false}
                    showFileNames = {false}
                    showPreviewsInDropzone = {false}
                    dropzoneText="Trageți unul sau mai multe fișiere aici, sau faceți click oriunde în acest dreptunghi pentru a le selecta"
                    showAlerts={false}
                    filesLimit={10}
                    maxFileSize={20000000}
                />
                <List className = {classes.list}>
                {files && files.map( (e,i) => {
                    return (
                    <span key = {`iii-${i}`}>
                        <ListItem >
                            <ListItemText>{e.name} ({Math.round(e.size/1024, 2) } kb)</ListItemText>
                            <ListItemSecondaryAction>
                                <Button variant = "text" size = "small" onClick = {removeFileHandler(i)}>
                                    <FaTimesCircle style = {{color: "red", fontSize: "1.2em"}} />
                                </Button>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider/>
                    </span>
                ) 
            } )}
                </List>
            </DialogContent>
            <DialogActions>
                <Grid item xs = {6}>
                <Button variant = "contained" size = "large" color = "secondary" fullWidth onClick = {closeHandler()}>
                    RENUNȚĂ ȘI ÎNCHIDE FEREASTRA
                </Button>
                </Grid>
                <Grid item xs = {6}>
                <Button variant = "contained" size = "large" color = "primary" fullWidth onClick = {closeHandler(files)}>
                   ÎNCARCĂ FIȘIERELE ȘI ATAȘEAZĂ-LE LA DOCUMENT
                </Button>
                </Grid>

            </DialogActions>
        </Dialog>
    )

}

export default ModalUpload


