import React, {useState} from 'react'
import { AppBar, IconButton, Drawer, makeStyles, Toolbar, Typography, 
     FormControlLabel, Checkbox, Grid, TextField, MenuItem, Button } from '@material-ui/core';
import { useQuery, useLazyQuery } from "@apollo/client"
import { FaTimesCircle } from 'react-icons/fa';
import moment from 'moment'
import { DOCTYPES } from './_graphql';
import { Fragment } from 'react';
import ContactInput from './ContactInput'


const useStyles = makeStyles(theme => ({
    drawerPaper: {
        width: "40vw", height: "auto"
    },
    toolbar: {
        background: theme.palette.primary.main, color: theme.palette.primary.contrastText,
        display: "flex", justifyContent: "space-between"
    },
    paper: {
        padding: theme.spacing(2), 
        // margin: theme.spacing(1),
        display: "flex", alignItems: "center", justifyContent: "flex-start",
        background: "GhostWhite"
    },
    inputProps: {
        fontSize: "9px", fontWeight: 800
    }
}))

const today = moment().format('YYYY-MM-DD')
const yearStart = moment(moment().format('YYYY')+".01.01").format('YYYY-MM-DD')
const yearEnd = moment(moment().format('YYYY')+".12.31").format('YYYY-MM-DD')
const initialParams = {like: []}
const initialBetween = {between: {row: "dt", start: yearStart, end: yearEnd}}

export default function RegFilters(props) {

    

    const classes = useStyles()
    const {data, error} = useQuery(DOCTYPES, {variables: {}})
    const [filters, setFilters] = useState(initialParams)

    const closeHandler = () => props.onClose()

    const hasKey = (filters, key) => {
        let res = filters.filter( el => el.key === key)
        return res.length > 0 ? true : false
    }

    const getKey = (filters, key) => {
        let res = filters.filter( el => el.key === key)
        return res.length > 0 ? res[0]['value']: ""
    }

    const checkboxHandler = name => ev => {
        console.log(ev.target.checked)
        switch (name) {

            case ("nr"): if (ev.target.checked){
                setFilters({...filters, like: [...filters.like, {key:name, value: ""}]})
                } else {
                    let f = {...filters}
                    let el = f.like.filter( el => el.key === name)
                    f.like.splice(f.like.indexOf(el[0]),1)
                    setFilters(f)
                }
            break;
            case ("contact"): if (ev.target.checked){
                setFilters({...filters, like: [...filters.like, {key:name, value: ""}]})
                } else {
                    let f = {...filters}
                    let el = f.like.filter( el => el.key === name)
                    f.like.splice(f.like.indexOf(el[0]),1)
                    setFilters(f)
                }
            break;
            case "doctypeID": if (ev.target.checked){
                setFilters({...filters, like: [...filters.like, {key:name, value: ""}]})
                } else {
                    let f = {...filters}
                    let el = f.like.filter( el => el.key === name)
                    f.like.splice(f.like.indexOf(el[0]),1)
                    setFilters(f)
                }
            break;
            case "title": if (ev.target.checked){
                setFilters({...filters, like: [...filters.like, {key:name, value: ""}]})
                } else {
                    let f = {...filters}
                    let el = f.like.filter( el => el.key === name)
                    f.like.splice(f.like.indexOf(el[0]),1)
                    setFilters(f)
                }
            break;
            case "contactID": if (ev.target.checked){
                setFilters({...filters, like: [...filters.like, {key:name, value: ""}]})
                } else {
                    let f = {...filters}
                    let el = f.like.filter( el => el.key === name)
                    f.like.splice(f.like.indexOf(el[0]),1)
                    setFilters(f)
                }
            break;
            case "between": if (ev.target.checked){
                setFilters({...filters, between: {row: "dt", start: today, end: yearEnd}})
                } else {
                    let f = {...filters}
                    let el = f.like.filter( el => el.key === name)
                    f.like.splice(f.like.indexOf(el[0]),1)
                    setFilters(f)
                }
            break;
        }
    }



    const setBetweenHandler = name => ev => {
        let f = {...filters}
       f.between[name]= ev.target.value
       setFilters(f)
    }
    const inputHandler = name => ev => {
        let f = {...filters}
        let cur = f.like.filter(elem => elem.key === name)
        f.like[f.like.indexOf(cur[0])]['value'] = ev.target.value
        setFilters(f)
    }

    const resetHandler = () => {
        setFilters(initialParams)
        props.onReset(true)
    }
    const applyHandler = () => props.onApply(filters)

    const contactHandler = ev => { 
        let f = {...filters}
        let cur = f.like.filter( el => el.key === "contactID")
        if(cur.length > 0 && cur[0]) {
            f.like[f.like.indexOf(cur[0])]['value'] = ev.id
            setFilters(f)
        }
    }

    return (
        <Drawer open={Boolean(props.open)} anchor="right" onClose={closeHandler} classes={{ paper: classes.drawerPaper }}>


            <Toolbar variant="dense" className={classes.toolbar} actions="x" disableGutters={true}>
                <Typography variant="caption" style={{ paddingLeft: "1em" }}>APLICĂ FILTRE</Typography>
                <IconButton variant="contained" color="inherit" onClick={closeHandler}><FaTimesCircle /></IconButton>
            </Toolbar>
            <div className={classes.paper}>
                <Grid container spacing={1}>
{/* nr */}
                    <Grid item xs={5} >
                        <FormControlLabel
                            control={<Checkbox size="small" onChange = {checkboxHandler('nr')}/>}
                            label={<small>nr. de înregistare conține</small>}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField 
                            value={getKey(filters.like, "nr")}
                            onChange = {inputHandler("nr")}
                            variant="outlined" size="small" InputProps = {{className:classes.inputProps}}
                            disabled = {!hasKey(filters.like, "nr")}
                        />
                    </Grid>
                    <Grid item xs={5} />
{/* between */}
                    <Grid item xs={5} >
                        <FormControlLabel
                            control={<Checkbox size="small" onChange = {checkboxHandler('between')}/>}
                            label={<small>interval de timp</small>}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        {/* {getBetween("start")} */}
                        <TextField 
                            variant="outlined" InputProps = {{className:classes.inputProps}} fullWidth size="small" type="date" 
                            value = {filters.between ? filters.between.start : yearStart}
                            disabled = {!filters.between}
                            
                            onChange = {setBetweenHandler("start")}
                            />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField 
                            variant="outlined" InputProps = {{className:classes.inputProps}} fullWidth size="small" type="date" 
                            value = {filters.between ? filters.between.end : yearEnd}
                            onChange = {setBetweenHandler("end")}
                        />
                    </Grid>
{/* tipdoc */}
            {data && data.doctypes  && (
                <Fragment>
                    <Grid item xs={5} >
                        <FormControlLabel
                            control={<Checkbox size="small" onChange = {checkboxHandler('doctypeID')} 
                            />}
                            label={<small>tip de document</small>}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField 
                            variant="outlined" fullWidth size="small" select InputProps = {{className:classes.inputProps}}
                            disabled = {!hasKey(filters.like, "doctypeID")}
                            value = {getKey(filters.like, "doctypeID")}
                            onChange = {inputHandler("doctypeID")}
                        >
                            {
                                data.doctypes.map((e, i) => (
                                    <MenuItem key={i} value={e.id}>{e.label}</MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>
                </Fragment>
            )}
                  
{/* contact */}
                    <Grid item xs={5} >
                        <FormControlLabel
                            control={<Checkbox size="small"  onChange = {checkboxHandler('contactID')} />}
                            label={<small>partener</small>}
                        />
                    </Grid>
                    <Grid item xs={7}>
                        <ContactInput 
                            data = {{id: getKey(filters.like, "contactID")} } 
                            onChange = {contactHandler} 
                            disabled = {!hasKey(filters.like, "contactID")}
                            label = "contact"
                        />
                        {/* <TextField 
                            variant="outlined" fullWidth size="small" value="test" InputProps = {{className:classes.inputProps}}
                            disabled = {!hasKey(filters.like, "contact")}
                            
                        /> */}
                    </Grid>
{/* title                     */}
                    <Grid item xs={5} >
                        <FormControlLabel
                            control={<Checkbox size="small" onChange = {checkboxHandler('title')}/>}
                            label={<small>titlul conține...</small>}
                        />
                    </Grid>
                    <Grid item xs={7}>
                        <TextField 
                            variant="outlined" fullWidth size="small" InputProps = {{className:classes.inputProps}}
                            disabled = {!hasKey(filters.like, "title")}
                            value = {getKey(filters.like, "title")}
                            onChange = {inputHandler("title")}
                        />
                    </Grid>
                    <Grid item xs = {6} />
                    <Grid item xs={3} >
                        <Button variant="contained" color="primary" fullWidth onClick = {resetHandler}>RESETEAZĂ</Button>
                    </Grid>
                    <Grid item xs={3}>
                        <Button variant="contained" color="secondary" fullWidth onClick  ={applyHandler}>APLICĂ</Button>
                    </Grid>
                    {/* <Grid item xs = {12}>
                        <pre>{JSON.stringify(filters, null,4)}</pre>
                    </Grid> */}
                </Grid>     
                    
            </div>

        </Drawer>
    )
}
