import React, {useState, useEffect, useRef} from 'react'
import Layout from '../../components/Layout'
import CustomToolbar from '../../components/CustomToolbar'
import { Button, Grid, LinearProgress, Paper, TextField, Typography } from '@material-ui/core'
import { useLazyQuery, useQuery } from "@apollo/client"
import {REPORT} from './_graphql'
import moment from 'moment'
import Antet from './Antet'
import Registru from './Registru'
import { useReactToPrint } from 'react-to-print'
import styled from 'styled-components'
import { FaArrowCircleRight, FaFilter } from 'react-icons/fa'
import {MdPrint} from 'react-icons/md'

const Styles = styled.div`

.landscape{
    @media print {
        @page { 
            size:  landscape;
            margin: 10mm 10mm 10mm 20mm; 
        }
        body { 
            
            /* writing-mode: tb-rl; */
        }
    }
}`


export default function Rapoarte(props) {

    const Printable = useRef()
    const [offset, setOffset] = useState(0)
    const [limit, setLimit] = useState(100)
    const [start, setStart] = useState(new Date(moment().format('YYYY') + ".01.01"))
    const [end, setEnd] = useState(new Date(moment().format('YYYY') + ".01.01"))

    // const {data, loading, error } = useQuery(REPORT, {variables: {
    //     data: {
    //         chunk: {
    //             offset: offset, limit: limit
    //         },
    //         between: {
    //             row: "dt", start: start, end: end
    //         }
    //     }
    // }})

    const [lazyQuery, {data, loading, error}] = useLazyQuery(REPORT)

   

    const filterHandler = () => {
        if (start && end ) {
            lazyQuery({
                variables: {
                    data: {
                        chunk: {
                            offset:0, limit: 100000,
                        },
                        between: {
                            row: "dt", start: start, end: end
                        }
                    }

                }
            })
        }
    }
    

    const startHandler = ev => setStart(ev.target.value)
    const endHandler = ev => setEnd(ev.target.value)

    const printHandler = useReactToPrint({
        content: () => Printable.current,
    });

    return (
        <Layout>
            <React.Fragment>
                <CustomToolbar 
                    title = "SITUAȚII ȘI RAPOARTE"
                    action = {
                        <Grid container alignItems = "center">
                            <TextField variant = "outlined" size = "small" type = "date" label = "de la"
                            value = {moment(start).format('YYYY-MM-DD')} onChange = {startHandler}
                            />&nbsp;
                            <FaArrowCircleRight/>
                            &nbsp;
                            <TextField variant = "outlined" size = "small" type = "date" label = "până la"
                            value = {moment(end).format('YYYY-MM-DD')} onChange = {endHandler}
                            />
                            &nbsp;&nbsp;
                            <Button onClick = {filterHandler} variant = "outlined" color = "primary" size = "large">
                                <FaFilter size="1rem"/>
                                &nbsp;&nbsp;
                                FILTREAZA
                            </Button>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button variant = "contained" color = "secondary" onClick = {printHandler}>
                        <MdPrint size = "1.2rem"/>
                        &nbsp;&nbsp;
                        TIPĂREȘTE
                        </Button>
                    </Grid>
                }
                />
                <Styles>
                    <Paper style = {{height: "75vh", overflow: "auto", margin: "30px", padding: "10px", textAlign: "center"}}>
                        { loading && <LinearProgress />}
                        { error && <pre>{JSON.stringify(error)}</pre>}
                        { data  && (
                            <div ref = {Printable} className = "landscape">
                            <Antet {...data.report.company} />
                            <Registru 
                                start = {start} end = {end}
                                entries = {data.report.entries}
                            />
                            </div>
                        )}
                    </Paper>
                </Styles>
                
            </React.Fragment>
        </Layout>
    )
}
