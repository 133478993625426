import React from 'react'
import Layout from '../../components/Layout'
import CustomToolbar from '../../components/CustomToolbar'
import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/client'
import { TextField, Button, Box, Grid, makeStyles, MenuItem, ListItem, List, Divider, Container } from '@material-ui/core'
import { QUERY_DOCTYPES, MUTATION_ENTRY } from './_graphql'
import { Context } from '../../utils/Store'
import moment from 'moment'
import { FaArrowCircleDown, FaArrowCircleUp } from 'react-icons/fa'
import { Alert } from '@material-ui/lab'


const NEXT_NUMBER_QUERY = gql(`query nextEntry {
    nextNumber
  }`)


const MUTATION_WORKFLOW = gql(`mutation wkf($entryID: ID! $companyID: ID!, $dt: String $currentUserID: ID $nextUserID: ID 
    $statusID: ID! $title: String){
    workflow( entryID: $entryID, companyID: $companyID, dt: $dt, currentUserID: $currentUserID, nextUserID: $nextUserID, statusID: $statusID, title: $title )
}`)


const useStyles = makeStyles(t => ({
    root: { flexGrow: 1, textAlign: 'center', display: 'flex', alignItems: "center", height: "75%" }
}))




export default function Nou(props) {
    const classes = useStyles()

    const { error, loading, data } = useQuery(NEXT_NUMBER_QUERY, {fetchPolicy:'network-only'})
    const doctp = useQuery(QUERY_DOCTYPES, {fetchPolicy:'network-only'})
    const [craEntry, dispatchCraEntry ] = useMutation(MUTATION_ENTRY) 
    const [createWorkflow, dispatchWorkflow] = useMutation(MUTATION_WORKFLOW)
    const [context, dispatch] = React.useContext(Context)

    const [item, setItem] = React.useState({
        nr: '',
        dt: new Date(),
        doctypeID: 1,
        direction: 'in',
        
    })


    const [doctypes, setDoctypes] = React.useState([])

    React.useEffect(() => {
        if (data) setItem({ ...item, nr: data.nextNumber })
        if (doctp.data) setDoctypes(doctp.data.doctypes)

    }, [data, doctp.data])

    React.useEffect(() => {
        if ( context ) {setItem({
            ...item,
            userID: context.user.id, 
            companyID: context.user.companyID
        })}
    }, [context])
    
    const doctypeHandler = ev => setItem({ ...item, doctypeID: ev.target.value })
    
    const directionHandler = () => setItem({
        ...item,
        direction: item.direction === "in" ? "out" : "in"
     })

    const dateHandler = ev => setItem({...item, dt: moment(ev.target.value).toDate()})
    const submitHandler = async () => {
        let result
        try {
            result = await craEntry({variables: {...item, nr: item.nr.toString()}})
            let rst = await result.data
            console.log("Rst:", rst)
            
        } catch (error) {
            result = {err: error}
        } finally {
            if ( result.err ) {} else {
                createWorkflow({variables: {
                    entryID: result.data.entry.id,
                    companyID: context.user.companyID,
                    currentUserID: context.user.id, 
                    nextUserID: context.user.id,
                    dt: item.dt,
                    title: "înregistrare document",
                    statusID:1 
                }}).then ( () => {
                    props.history.push(`/document/${result.data.entry.id}`)
                })
                   
                

            }
           
        }
    }

    return (
        <Layout>

            <CustomToolbar title="ADĂUGARE ÎNREGISTRARE NOUĂ">
                NOU
            </CustomToolbar>
            {data && (
                <div className={classes.root}>
                    <Container maxWidth="xs">
                        <Grid container justify="center" spacing={1}>
                            <Grid item xs ={12}>
                                <Button 
                                    variant = "outlined" size = "large"  fullWidth style = {{minHeight: "4rem"}}
                                    color = {item.direction === "in" ? "secondary" : "primary"}
                                    onClick = {directionHandler}
                                    >
                                        <div>
                                    {item.direction ==="in" 
                                        ? <FaArrowCircleDown style = {{fontSize: '3rem', marginRight: '10px'}} /> 
                                        : <FaArrowCircleUp style = {{fontSize: '3rem', marginRight: '10px'}} /> 
                                    }
                                    <br/>
                                    {item.direction ==="in" ? "INTRARE" : "IEȘIRE" }
                                    </div>
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField select variant="outlined" size="medium" value={item.doctypeID} fullWidth
                                    onChange={doctypeHandler} label="tip document">
                                    {doctypes.map((e, i) => (
                                        <MenuItem value={e.id} key={`cxxx-${i}`}>
                                            <strong>{e.label}</strong>
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField variant="outlined" size="medium" value={item.nr} label="nr." fullWidth
                                onChange = {ev => setItem({...item, nr: ev.target.value})}
                                />
                            </Grid>
                            <Grid item xs={12} sm={9}>
                                <TextField variant="outlined" size="medium" type="date"  label="data"  fullWidth
                                value={moment(item.dt).format('YYYY-MM-DD')}
                                onChange = {dateHandler}
                            />
                            </Grid>
                            <Grid item xs = {12}>
                                <Button 
                                    variant = "outlined" size = "large" color = "primary" fullWidth style = {{height: "4rem"}}
                                    onClick = {submitHandler}
                                >
                                        <strong>CONFIRMĂ</strong>
                                </Button>
                            </Grid>
                                {/* <pre>{JSON.stringify(item, null,4)}</pre> */}
                            <Grid item xs = {12}>
                                <Alert severity = "info">
                                    Numărul de înregistrare este alocat provizoriu. Acesta va fi păstrat la momentul apăsării butonului {' '}
                                    <strong>CONFIRMĂ</strong>. În cazul în care acesta nu va mai fi dispobibil va fi alocat automat
                                    următorul număr incremental disponibil.
                                </Alert>
                            </Grid>

                        </Grid>
                    </Container>
                </div>
            )}
        </Layout>
    )
}
