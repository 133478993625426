import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import React, { Fragment } from 'react'

const useStyles = makeStyles( theme => ({
    box: {
        position: "relative",
        border: "0.01rem solid lightgrey",
        padding: "5px",
        width: "100%", height: "inherit", overflow: "none",
        boxShadow: "1px 1px 3px lightgrey"
       
        
    },
    titleContainer: {
        position: "absolute",
        display: "flex",
        alignItems:"flex-start", justifyContent: "center",
        width:"100%",
        marginTop: "-12px", 
        paddingLeft: "10px", paddingRight: "10px", 
       
        // paddingBottom: "10px",
        // borderBottom: "2px solid red"
        
    },
    title: {
        
        // backgroundColor: "white", 
       
        
        fontWeight: 800,
        fontSize: "0.75em",
        // height: "20px",
        background: "none", 
        paddingBottom: "15px"
       
        // width: "99%"
    },
    content: {
        
        margin: theme.spacing(1),
        height: "100%"

    }
}))

export default function Boxy({title, children, contentStyle, onChange, ...rest}) {
    const classes = useStyles()
    return (
        <div className ={classes.box} {...rest}>
           {title && (
                <div className = {classes.titleContainer}>
                   <div className = {classes.title}>{title}</div>
                  
                </div>
              
           )}
           <div className={classes.content} style = {contentStyle ?? null}>
           { children }
           </div>
        </div>
    )
}
