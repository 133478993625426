import React, {useState, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { MUTATION_CONTACT } from './_graphql'
import { Dialog, DialogTitle, DialogActions, DialogContent, Button,  Grid, Slide, makeStyles} from "@material-ui/core"
import FunkyInput from '../../components/FunkyInput'


export const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const fields = [
    { name: "name", value: "", label: "nume/firma", xs: 12, sm: 4 },
    { name: "surname", value: "", label: "prenume (pf)", xs: 12, sm: 4 },
    { name: "cnp", value: "", label: "CNP", xs: 12, sm: 2 },
    { name: "cui", value: "", label: "CUI", xs: 12, sm: 2 },
    { name: "str", value: "", label: "strada", xs: 12, sm: 2 },
    { name: "nr", value: "", label: "nr.", xs: 12, sm: 1 },
    { name: "bl", value: "", label: "bloc", xs: 12, sm: 1 },
    { name: "sc", value: "", label: "scara", xs: 12, sm: 1 },
    { name: "et", value: "", label: "etaj", xs: 12, sm: 1 },
    { name: "ap", value: "", label: "ap.", xs: 12, sm: 1 },
    { name: "loc", value: "", label: "localitate", xs: 12, sm: 2 },
    { name: "jud", value: "", label: "județ", xs: 12, sm: 2 },
    { name: "zip", value: "", label: "cod poștal", xs: 12, sm: 1 },
    { name: "tel1", value: "", label: "telefon 1", xs: 12, sm: 2 },
    { name: "tel2", value: "", label: "telefon 2", xs: 12, sm: 2 },
    { name: "email", value: "", label: "email", xs: 12, sm: 2 },
    { name: "obs", value: "", label: "observații", xs: 12, sm: 6 }
]

const useStyles = makeStyles(theme => ({
    dialogPaper: { minHeight: "50vh", minWidth: "90vw"}
}))

const ContactModal = props => {
    const classes = useStyles()
    const [ item, setItem ] = useState({})
    const [ execQuery, setExecQuery ] = useState(false)

    useEffect(() => {
        if ( props.data) setItem(props.data)
    }, [props])

    const inputHandler = name => ev => {
        setItem({...item, [name]: ev.target.value })
    }

    const [cra, {dispatchCra}] = useMutation(MUTATION_CONTACT, { variables: item })

    const closeHandler = name => () => {
        if (!name) { 
            props.onClose()
        } else {
            let q = cra({variables: {...name}})
            q.then( 
                
                r => {
                    console.log("inside modal, id:", r.data.contact )
                    props.onClose(r.data.contact)
                }, 
                err => props.onClose() 
            )
        }
    }

    

    return (
        <Dialog 
            open = {props.open}
            onClose = {closeHandler()}
            classes={{ paper: classes.dialogPaper }}
            TransitionComponent = {Transition}
            keepMounted
           
        >
            {/* {console.log("rendered item: ", item)} */}
            <DialogTitle>ADĂGARE PERSOANĂ NOUĂ</DialogTitle>
            <DialogContent>
                {item ? (
                    <Grid container justify = "space-between" spacing = {1}>
                        {[...fields].map((e, i) => 
                             <Grid item xs={e.xs} sm={e.sm} key={`input-${i}`}>
                                <FunkyInput
                                    label={e.label}
                                    placeholder={e.label}
                                    value={item[e.name] || e.value}
                                    onChange={inputHandler(e.name)}
                                />
                             </Grid>
                            )}
                    </Grid>
                    
                ): <div>așteaptă un pic, te rog...</div>}

            </DialogContent>
            <DialogActions>
                <Button onClick = {closeHandler()}>RENUNTĂ</Button>
                <Button onClick = {closeHandler(item)}>CONFIRMĂ</Button>
            </DialogActions>
        </Dialog>
    )

}

export default ContactModal
