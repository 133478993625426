import React from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { QUERY_COMPANIES, REMOVE_COMPANY } from './_graphql'
import { makeStyles, Grid, TextField, Typography, Toolbar, Button , Tabs, Tab} from '@material-ui/core'
import {Dialog, DialogTitle, DialogContent, DialogActions} from '@material-ui/core'
import { FaCheck, FaTimes } from 'react-icons/fa'
import Details from './Details'


const useStyles = makeStyles(t => ({
    toolbar: { background: t.palette.grey[200]}
}))



const  Superadmin = props =>  {
    const classes = useStyles()
    const [item, setItem] = React.useState()
    const [tabIndex, setTabIndex] = React.useState(0)
    const [ currentCompany, setCurrentCompany] = React.useState()
    const [ removeModal, setRemoveModal ] = React.useState()
    const {data, loading, error, refetch } = useQuery(QUERY_COMPANIES)
    const [ rmCompany, dispatchRmCompany ] = useMutation(REMOVE_COMPANY)


    const handleTabChange = (ev, val) => {
        setTabIndex(val)
        setCurrentCompany(item[val])
    }

    const contextHandler = data => ev => {
        ev.preventDefault()
        console.log("Data:", data)
        setRemoveModal(data)
    }

    const removeCompanyHandler = ev => () => {
        if ( !ev ) {
            setRemoveModal()
        } else {
            rmCompany({variables: {id: ev.id}}).then( r => {
                if ( r.data.removeCompany ) {
                    setRemoveModal()
                    setTabIndex(1)
                    refetch()
                    setCurrentCompany()
                }
            })
            // company removal mutation!!! (ev.id, superAdminPassword)
        }

    }
 
    React.useEffect(() => {
        if ( data && data.companies) setItem(data.companies)
    }, [data])

    return (
        <div>
            {loading && <div>SE INCARCA...</div>}
            {error && <pre>{JSON.stringify(error,null,4)}</pre>}
            
            <Grid container spacing = {2}>
                <Grid item xs = {3}>
                   
                    <Tabs
                        value={tabIndex}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        orientation="vertical"
                        variant="scrollable"
                        scrollButtons="auto"
                        >
                            { item && item.map((e,i) => ( 
                                <Tab value = {i} label = {e.name} key = {`c-${i}`}  onContextMenu = {contextHandler(e)}/>
                            ))}
                    </Tabs>
                   
                </Grid>
                <Grid item xs = {9}>
                <Details data = {currentCompany} onChanges = {() => refetch()}/>
                </Grid>
                
            </Grid>
            <Dialog open = { Boolean(removeModal)} onClose = {removeCompanyHandler()}>
            <DialogTitle>Eliminare companie </DialogTitle>
            <DialogContent >
                <div align = "center">
                Vei elimina definitv compania <br/>
                <strong style = {{color: "red"}}>{removeModal && removeModal.name}</strong><br/><br/>
                <strong>Ești absolut sigur că vrei să faci asta?</strong><br/>
                </div>
            </DialogContent>
            <DialogActions>
                <Button size = "small" variant = "contained" color = "primary" onClick = {removeCompanyHandler(removeModal)}>DA</Button>
                <Button size = "small" variant = "contained" color = "secondary" onClick = {removeCompanyHandler()}>NU</Button>
            </DialogActions>
        </Dialog>

            

        </div>
    )
}


export default Superadmin