import React, { useMemo, useRef } from 'react'
import ReactTable7 from './ReactTable7'
import { SelectColumnFilter } from './ReactTable7/filters'
import moment from 'moment'
import { FaArrowAltCircleDown, FaArrowAltCircleUp, FaArrowAltCircleRight, FaPaperclip } from 'react-icons/fa';
import { Grid, Button, IconButton, Menu, Tooltip, makeStyles,  LinearProgress, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
    tooltip: {
            
            "&& :nth-child(n)": { background: "white", color: "navy", border: "none" },
    },
    popper: {
        background: "white",border: "1px solid red",
        "&& :nth-child(n)": { background:"white"}
    },

    item: {
        fontSize: "0.7rem", 
        // color: theme.palette.primary.contrastText,
        textAlign: "center",
        // display: "flex", alignItems: "center", justifyContent
        // "&& :before": {}
    }
}))

const EntriesList = props => {

    const classes = useStyles()
    const [data, setData] = React.useState()
    const [files, setFiles] = React.useState()
    const [anchorEl, setAnchorEl] = React.useState()
    const history = useHistory()
    const nodeRef = useRef()
    const theme = useTheme()

    React.useEffect(() => {
        if (props && props.data) {
            const registru = props.data.map(el => {
                // console.log("e:", el.direction)
                return {
                    ...el,
                    realName: el.contact ? el.contact.name + " " + (el.contact.surname ? el.contact.surname :"") : "",
                    nr: el.nr ? el.nr : "nr.lipsă",
                    realDate: el.dt ? moment(el.dt).format('DD.MM.YYYY') : 'dată lipsă',
                    dir: el.direction === "in" ? "INTRARE" : "IEȘIRE",
                    filesCount: el.files.length,
                    nextUser: el.lastWorkflow ? el.lastWorkflow.status.title : "",
                    status: el.lastWorkflow && el.lastWorkflow.status && el.lastWorkflow.status.desc 
                        ?el.lastWorkflow.status.desc : "NECUNOSCUTĂ"
                }})
            setData(registru)
        }
    }, [props])

    const documentHandler = id => () => history.push(`/document/${id}`)
    const columns = useMemo(() => [
        
        {
            Header: <div align ="center"><small>SENS</small></div>,
            accessor: "direction",
            width: 50,
            style: {  textAlign: "left" },
            Cell: ({row}) => {
                let dir = row.original.dir
                return dir === "INTRARE"
                    ? <div align="center">
                        <FaArrowAltCircleDown style={{ color: "red", fontSize: "1.3em" }} />
                    </div>
                    : <div align="center">
                        <FaArrowAltCircleUp style={{ color: "navy", fontSize: "1.3em" }} />
                    </div>
            },
            Filter: SelectColumnFilter,
            filter: 'equals',
        },

        {
            Header: <div align ="center"><small>NR.</small></div>,
            accessor: "nr",
            style: {  textAlign: "center" },
            Cell: r => <div className={classes.item}>{r.cell.row.original.nr}</div>,
            width: 80
        },
        {
            Header: <div align ="center"><small>DIN DATA</small></div>,
            accessor: "realDate",
            Cell: r => <div className={classes.item} >{r.cell.row.original.realDate}</div>,
            style: { textAlign: "center" },
            width: 100
        },
        {
            Header:<div align ="center"><small>TIP DOCUMENT</small></div>,
            accessor: "doctype.label",
            Cell: r => (<div className={classes.item} style={{ fontSize: "0.7rem" }}>
                {r.cell.row.original.doctype.label}
            </div>),
            style: {  textAlign: "center" },
            Filter: SelectColumnFilter,
            filter: 'equals',
            width: 120

        },
        {
            Header: <div align ="center"><small>SUMAR DOCUMENT</small></div>,
            accessor: 'title',
            // style: { width: "20%" },
            Cell: r => <div className={classes.item} style={{ textAlign: "left" }}>
                {r.row.original.title}
            </div>,
            width: 230
        },
        {
            Header: <div align ="center"><small>PARTENER</small></div>,
            accessor: 'realName',
            disableSortBy: false,
            // style: { width: "10%" },
            Cell: r => <div className={classes.item} style={{ textAlign: "left" }}>{r.row.original.realName}</div>,
            width: 150
        },
        {
            Header: () => <div align ="center"><small>STARE</small></div>,
            accessor: 'status',
            Cell: ({row}) => <div className={classes.item}>{row.original.status}</div>,
            style: { width: "5%" },
            Filter: SelectColumnFilter,
            filter: 'equals',
            onClick: (ev, row) => console.log("row:", ev,row),
            width: 100
        },
        {
            Header: <div align ="center"><small>ÎN LUCRU LA</small></div>,
            accessor: 'lastWorkflow.nextUser.name',
            // style: { width: '10%' },
            Filter: SelectColumnFilter,
            filter: 'equals',
            Cell: r => <div className={classes.item} style={{ textAlign: "left" }}>
                {r.row.original.lastWorkflow
                    && r.row.original.lastWorkflow.nextUser
                    && r.row.original.lastWorkflow.nextUser.name}</div>,
            width: 100
        },

        {
            Header: <div align ="center"><small>ULTIMA ACTIVITATE</small></div>,
            accessor: 'lastWorkflow.title',
            Cell: r => (
                <div className={classes.item}>
                    {r.row.original.lastWorkflow && r.row.original.lastWorkflow.title}
                </div>),
            width:150
        },
        {
            Header: <div align="center"><FaPaperclip style={{ fontSize: "1.3rem", marginTop: "6px" }} /></div>,
            id: "fisiere",
            Cell: r => (
                <div align="center">
                    <Tooltip
                        classes ={{tooltip: classes.tooltip , popper: classes.popper}}
                        title={
                            <ul style={{ listStyle: "none", paddingLeft: 0 }}>
                                {r.row.original.files.map((e, i) => (
                                    <li key={`file-${i}`}>
                                        {e.name}
                                    </li>
                                ))}
                            </ul>
                        }
                    >
                        <IconButton
                            size="small" color="primary" align="center"
                            onClick={ev => {
                                ev.preventDefault();
                                console.log("Evvv:", ev.currentTarget)
                                setAnchorEl(ev.currentTarget)
                                setFiles(r.row.original.files)
                            }}
                        >
                           <small>{r.row.original.filesCount}</small>

                        </IconButton>
                    </Tooltip>
                </div>
            ),
            width: 50
        },

        {
            Header: () => <div align ="center"><small>DETALII</small></div>,
            id: "actiuni",
            disableSortBy: false,
            style: { textAlign: 'center' },
            Cell: r => <div align="center">
                <IconButton size="small" onClick={documentHandler(r.cell.row.original.id)}>
                    <FaArrowAltCircleRight style={{ color: theme.palette.primary.light, fontSize: '1.3em' }} />
                </IconButton>
            </div>,
            width: 70,
            onClick: row => console.log("row")
        }


    ],[] )
    return (
        <React.Fragment>
            {props.loading && <LinearProgress />}
            {/* <pre>{data && JSON.stringify(data, null, 4)}</pre> */}
            <ReactTable7
                
                data={data
                    && data.map(el => (
                        {
                            ...el,
                            realName: el.contact ? el.contact.name + " " + (el.contact.surname ? el.contact.surname :"") : "",
                            nr: el.nr ? el.nr : "nr.lipsă",
                            realDate: el.dt ? moment(el.dt).format('DD.MM.YYYY') : 'dată lipsă',
                            direction: el.direction === "in" ? "INTRARE" : "IEȘIRE",
                            filesCount: el.files.length,
                            nextUser: el.lastWorkflow ? el.lastWorkflow.status.title : ""
                        })) || []}

                columns={columns}
                loadMoreRows = {(ev, row)=> console.log("loadMoreRows:", ev, row)}
                // rowCount = {9999999}
                height = {600}
                getOffsetForRow = {ev => console.log("getOffsetForRow:", ev)}
                isRowLoaded = {ev => console.log("isRowLoaded:", ev)}
                        
            />

            {/* {console.log(nodeRef.current)} */}
            <Menu style={{ zIndex: 999999999 }} open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)} >
                
            </Menu>

        </React.Fragment>
    )
}

export default EntriesList