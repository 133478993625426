import React, { Fragment, useContext, useState } from 'react'
import { List, ListItem, Typography, Zoom, Divider, ListItemSecondaryAction, Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Grid }
    from '@material-ui/core'
import { FaTimes, FaEye, FaDownload, FaTimesCircle, FaTrashAlt, FaCloudDownloadAlt } from 'react-icons/fa'
import { Context } from '../../utils/Store'
import { MUTATION_REMOVE_FILE } from './_graphql'
import { useMutation } from '@apollo/client'
import config from '../../config'
import PdfPreviewModal from '../../components/PdfPreviewModal'
import download from "downloadjs"
import getMimeType from '../../lib/getMimeType'



const CustomZoom = React.forwardRef((props, ref) => <Zoom in={true} ref={ref} {...props} />)

const FilesList = props => {

    const [context, dispatch] = useContext(Context)
    const [confirm, setConfirm] = React.useState()
    const [removeFile, dispatchRemove] = useMutation(MUTATION_REMOVE_FILE)
    const [file, setFile] = useState(null)
    const removeFileConfirmHandler = (i, e) => () => { setConfirm({ index: i, item: e }) }

    const removeFileHandler = id => ev => {
        console.log(confirm)
        if (!id) {
            setConfirm()
        } else {
            console.log("id:", id)
            removeFile({ variables: { id: id } }).then(
                r => {
                    if (r.data.removeFile) {

                        let nf = [...props.data]
                        console.log("nf: ", nf, confirm.index)
                        nf.splice(confirm.index, 1)
                        console.log("new files:", nf)
                        props.onChange(nf)
                        setConfirm()
                    }
                },
                err => {
                    console.log("Err:", err)
                }
            )
        }
    }


    const downloadHandler = e => async () => {
        let res;
        const url = `${config.api}/download/${btoa(JSON.stringify({ "id": e.id }))}`
        console.log("url:", url)
        res = await fetch(url)
        res = await res.blob()

            return download(res, e.name, getMimeType(e))
    }

    return (
        <React.Fragment>
            
                {props && props.data && props.data.map((e, i) => (
                    <Grid container justify='space-between' alignItems='center' spacing={1} key = {"row-"+i}>
                        <Grid item xs={12} sm={8}>
                            <Typography variant="caption" component="a"
                                href={`${config.api}/download/${btoa(JSON.stringify({ "id": e.id }))}`}>
                                {e.name} ({Math.round(e.size / 1024, 0)} kb)
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Grid container justify="flex-end" alignItems="center" spacing={2}>
                                <Grid item>
                                <FaTrashAlt
                                    style={{
                                        color: context.user.id.toString() === e.userID.toString() ? "red" : "grey",
                                        fontSize: "1.2em"
                                    }}
                                    onClick={context.user.id.toString() === e.userID.toString()
                                        ? removeFileConfirmHandler(i, e)
                                        : ev => ev.preventDefault()
                                    }
                                />
                                </Grid>
                                <Grid item>
                                    <FaCloudDownloadAlt size="1.2rem" color="lightgreen"
                                        onClick={downloadHandler(e)} />
                                </Grid>
                                <Grid item>
                                    <FaEye size="1.2rem" color="navy"
                                        onClick={() => setFile({
                                        content: `${config.api}/download/${btoa(JSON.stringify({ "id": e.id }))}`,
                                        title: e.name
                                    })} />
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>


                   </Grid>
                ))}
            <Dialog open={Boolean(confirm)} onClose={removeFileHandler} TransitionComponent={CustomZoom}

            >
                <DialogTitle> ȘTERGERE FIȘIER!</DialogTitle>
                <DialogContent>
                    <h4 style={{ color: "red" }}>ATENȚIE!!</h4>
                    <DialogContentText>
                        Se va șterge definitiv fișierul <br />
                        <strong>{confirm && confirm.item.name}</strong>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={removeFileHandler(confirm && confirm.item.id)}>CONFIRMĂ</Button>
                    <Button variant="contained" color="secondary" onClick={removeFileHandler()}>RENUNȚĂ</Button>
                </DialogActions>
            </Dialog>
            {file && <PdfPreviewModal data={{ file: file.content, title: file.title }} onClose={() => setFile(null)} />}
        </React.Fragment>
    )
}

export default FilesList