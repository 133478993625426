import React from 'react'
import { makeStyles, Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid } from '@material-ui/core'
import FunkyInput from '../../components/FunkyInput'
import { FaTimesCircle, FaTrash } from 'react-icons/fa'
import { useMutation } from '@apollo/client'
import { MUTATE_CONTACT, REMOVE_CONTACT } from './_graphql'

const useStyles = makeStyles(theme => ({
    dialog: { minHeight: '45vh', minWidth: '60vw' }
}))

const fields = [
    { value: "name", label: "nume(pf)/denumire(pj)", xs: 12, sm: 4 },
    { value: "surname", label: "prenume (pf)", xs: 12, sm: 4 },
    { value: "cnp", label: "CNP", xs: 12, sm: 2 },
    { value: "cui", label: "CUI", xs: 12, sm: 2 },
    { value: "str", label: "strada", xs: 12, sm: 5 },
    { value: "nr", label: "nr.", xs: 12, sm: 1 },
    { value: "bl", label: "bloc", xs: 12, sm: 1 },
    { value: "sc", label: "scara", xs: 12, sm: 1 },
    { value: "et", label: "etaj", xs: 12, sm: 1 },
    { value: "ap", label: "ap.", xs: 12, sm: 1 },
    { value: "zip", label: "cod poștal", xs: 12, sm: 2 },
    { value: "loc", label: "localitate", xs: 12, sm: 6 },
    { value: "jud", label: "județ", xs: 12, sm: 6 },
    { value: "tel1", label: "telefon 1", xs: 12, sm: 3 },
    { value: "tel2", label: "telefon 2", xs: 12, sm: 3 },
    { value: "fax", label: "fax", xs: 12, sm: 3 },
    { value: "email", label: "email", xs: 12, sm: 3 },
    { value: "obs", label: "observații", xs: 12, sm: 12 }
]

const ContactModal = props => {
    const classes = useStyles()
    const [item, setItem] = React.useState()
    const [cruContact, dispatchCru] = useMutation(MUTATE_CONTACT)
    const [removeContact, dispatchRemove] = useMutation(REMOVE_CONTACT)
    const [confirmRemove, setConfirmRemove] = React.useState()

    React.useEffect(() => {
        if (props && props.data) {
            setItem(props.data)
        } else { setItem() }
    }, [props])

    const inputHandler = name => ev => setItem({ ...item, [name]: ev.target.value })

    const closeHandler = ev => () => {
        console.log("ev:", ev)
        if (ev) {
            cruContact({ variables: ev }).then(r => props.onClose(r.data.contact))
        } else {
            props.onClose()
        }

    }

    const removeConfirmHandler = () => setConfirmRemove(true)

    const removeHandler = () => {
        removeContact({variables:{id: [item.id]}}).then( 
            r => {
                setConfirmRemove()
                props.onClose(true)
            },
            err => {
                console.log("err:", err)
                setConfirmRemove(); props.onClose()
            }
        )

    }

    const hasEntries = () => item && item.entries && item.entries.length > 0 ? true : false

    return (
        <React.Fragment>
            <Dialog
                open={Boolean(item)}
                onClose={closeHandler()}
                classes={{ paper: classes.dialog }}
            >
                <DialogTitle>
                    <Grid container justify="space-between" align="center">
                        <Grid item xs={10}>
                            {item && item.id ? 'EDITARE' : 'ADĂUGARE'} CONTACT
                    </Grid>
                        <Grid item align="right">
                            <Button
                                size="large" style={{ fontSize: '1.4rem' }} color="secondary"
                                disabled={hasEntries()}
                                onClick={removeConfirmHandler}
                            >
                                <FaTrash />
                            </Button>
                        </Grid>
                        <Grid item align="right">
                            <Button size="large" color="primary">
                                <FaTimesCircle style={{ fontSize: '1.5rem', }} onClick={closeHandler()} />
                            </Button>
                        </Grid>

                    </Grid>

                </DialogTitle>
                <DialogContent>

                    <Grid container spacing={1}>

                        {fields.map((e, i) => {

                            return (
                                <Grid item xs={e.xs} sm={e.sm} key={`input-${i}`}>
                                    <FunkyInput
                                        label={e.label}
                                        placeholder={e.label}
                                        value={item && item[e.value] || ''}
                                        onChange={inputHandler(e.value)}
                                    // disabled = {props.disabled}
                                    />
                                </Grid>
                            )
                        })}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="secondary" onClick={closeHandler()}>RENUNȚĂ</Button>
                    <Button variant="contained" color="primary" onClick={closeHandler(item)}>CONFIRMĂ</Button>
                </DialogActions>
            </Dialog>
            <Dialog open = {Boolean(confirmRemove)} onClose = { () => setConfirmRemove() } >
                <DialogTitle>CONFIRMARE ȘTERGERE CONTACT</DialogTitle>
                <DialogContent>
                    Veți șterge definitiv persoana {item && item.name}! <br/>
                    Confirmați operațiunea!
                </DialogContent>
                <DialogActions>
                    <Button variant = "contained" color = "primary" onClick = {removeHandler}>CONFIRM</Button>
                    <Button variant = "contained" color = "secondary" onClick ={() => setConfirmRemove()}>RENUNȚ</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}


export default ContactModal