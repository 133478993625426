import React from "react"
import { makeStyles, Card, Grid, Typography, Toolbar } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
    card: { padding: 0, height: '100%', border: `1px solid ${theme.palette.grey[400]}`, boxShadow: '1px 1px 2px lightgrey' },
    cardToolbar: { 
        background: theme.palette.grey[100], color: theme.palette.primary.dark, margin: 0, minHeight: theme.spacing(4),
        marginRight: 0, paddingRight: 0, paddingLeft: theme.spacing(1)
    },
    cardContent: { padding: theme.spacing(1), height: "26vh", border:"0px solid green", overflow: "auto" }
}))


export const RichCard = props => {
    const classes = useStyles()
    return (
        <Card className={classes.card} style = {props.style}>
            {Boolean(props.title) && (
                <Toolbar variant="dense" className={classes.cardToolbar}>
                    <Grid container justify = "space-between" alignItems = "center" >
                    <Typography variant="caption"><strong>{props.title}</strong></Typography>
                        <div align = "right">{props.action  && <div align = "right">{props.action}</div>}</div>
                    </Grid>
                </Toolbar>
            )}
            <div className={classes.cardContent} style = {props.contentStyle}>
                {props.children}
            </div>
        </Card>
    )
}