import { Button, Divider, Grid, Hidden, IconButton, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import {  FaArrowAltCircleRight, FaArrowAltCircleLeft } from 'react-icons/fa'
import Boxy from '../../../components/Boxy'
import FunkyInput from '../../../components/FunkyInput'
import { TopBoxContainer } from '../../../components/TopBoxContainer'
import { useHistory } from 'react-router'
import DocType from '../DocType'
import moment from 'moment'

export const Placeholder = () => <div style={{ border: "1px solid red", height: "30px" }} />

export default function DocHeader({ data,  disabled, onChange, onSubmit }) {

    const history = useHistory()
    let item = data ? { ...data, deadline: (data.deadline || new Date()) } : {}

    const [days, setDays] = useState(0)

    useEffect(() => {
        setDays(moment(item.deadline).diff(moment(item.dt), "days"))
    }, [data])

    const docTypeHandler = ev => {
        console.log("EV:", ev)
        onChange({
            ...item,
            doctype: ev,
            doctypeID: ev.id,
            deadline: moment(item.dt).add(ev.days, 'days').toDate(),
            title: item.direction === "in" ?  ev.label + "  - nr     din   " : item.title 
        })

    }

    const inputHandler = name => ev => {
        let ni = { ...item }
        ni[name] = name === "dt" ? moment(ev.target.value) : ev.target.value
        onChange(ni)
    }

    const deadLineHandler = ev => {
        let ni = { ...item }
        ni.deadline = moment(ev.target.value).toDate()
        onChange(ni)
    }

    const daysChangeHandler = ev => {
        const d = ev.target.value || 0
        let ni = { 
            ...item,
            deadline: moment(item.dt).add(d, "days").toDate()
         }
        onChange(ni)

    }

    const switchHandler = name => () => {
        let ni = {...item}
        if ( ni.direction !== name ) {
            ni.direction = name
        }
        onChange(ni)
    }

    return (

        <Boxy 
            title="INDETIFICARE DOCUMENT" 
            contentStyle={{ paddingTop: "4px", background: "whitesmoke" }} 
            style={{ background: "whitesmoke",}}>
            <Grid container justify="space-between" alignItems="center" spacing={0}>
                {/* <Grid item align="center"> */}
                    {/* <IconButton onClick={() => history.push("/")} size="medium"><FaHome size="3rem" /></IconButton> */}
                {/* </Grid> */}

                <Grid item xs ={12} sm = {12}>
                    <Grid container justify="space-between" alignItems="center" spacing={1} >
 
                        {/* box nr data */}
                        <Grid item sm={4} xs={12} style={{ background: "none" }}>
                            <Boxy title="ÎNREGISTRARE" style = {{height: "105px", background: "aliceblue"}}>
                                <Grid container spacing={1}>
                                    <Grid item xs={4} sm = {4} md = {5} lg = {3}>
                                        <FunkyInput
                                            label="NR" type="number" min="0" step="1"
                                            value={item.nr}
                                            onChange={inputHandler("nr")}
                                        />
                                    </Grid>
                                    <Grid item xs={8} sm = {6} md = {7} lg = {5}>
                                        <FunkyInput
                                            type="date" label="DATA"
                                            value={moment(item.dt).format("YYYY-MM-DD")}
                                            onChange={inputHandler("dt")}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DocType
                                            {...item} disabled={disabled}
                                            onChange={docTypeHandler}
                                        />
                                    </Grid>
                                </Grid>
                            </Boxy>
                        </Grid>



                        {/* box 2 */}
                        <Grid item sm={3} xs = {12}>
                            <Boxy  style = {{height: "105px", background: "cornsilk"}}>
                                <Grid container spacing={1}>
                                    <Grid item sm = {8} xs ={8}>
                                        <FunkyInput
                                            type="date"
                                            label="TERMEN"
                                            onChange={deadLineHandler}
                                            value={item.deadline
                                                ? moment(item.deadline).format('YYYY-MM-DD')
                                                : moment().add('30', 'days').format('YYYY-MM-DD')}
                                            disabled={disabled}
                                        />
                                    </Grid>
                                    <Grid item sm = {4} xs = {4}>
                                        <FunkyInput 
                                            type="number" value={days} label="ZILE" 
                                            step={1}
                                            onChange = {daysChangeHandler}/>
                                    </Grid>
                                    <Grid item xs = {12}>
                                        <FunkyInput disabled label="TITULAR ÎNREGISTRARE" value = {item.user.name} />
                                    </Grid>
                                </Grid>
                            </Boxy>
                        </Grid>

                       {/* box in/out */}
                       <Grid item sm={2} xs ={12}>
                            <Boxy title="DIRECTIE" style = {{height: "105px", background: "white"}}>
                                <Button 
                                    size="medium" fullWidth
                                    variant={item.direction === "in" ? "contained" : "outlined"}
                                    color={item.direction === "in" ? "secondary" : "default"}
                                    onClick={switchHandler("in")}
                                    disabled={disabled}
                                    style={disabled && item.direction === "in" ? { border: '2px solid red' } : null}
                                >
                                        INTRARE
                                </Button>
                                <hr style={{ height: "1px", margin: 4, border: "none" }} />
                                <Button
                                    size="medium" fullWidth
                                    variant={item.direction === "out" ? "contained" : "outlined"}
                                    color={item.direction === "out" ? "primary" : "default"}
                                    style={disabled && item.direction === "out" ? { border: '2px solid red' } : null}
                                    onClick={switchHandler("out")}
                                    disabled={disabled}                                
                                >
                                    IESIRE
                                </Button>
                            </Boxy>
                        </Grid>                        
                
                        {/* box 3 */}
                        <Grid item xs={1} >
                                {/* nothing here yet */}
                        </Grid>
                        <Grid item sm={2} xs = {12} align="right">

                            {/* <Boxy contentStyle={{height: "90px",  margin:0, lineHeight:6}} > */}
                            <Button size="large" color="primary" variant="contained" fullWidth
                                onClick = {()=>onSubmit()}
                                style={{ height: "100px", margin: 0 }}>
                                    <Typography variant="h6">
                                CONFIRMĂ
                                </Typography>
                            </Button>
                            {/* </Boxy> */}
                        </Grid>
                        <Grid item xs={12}>
                            <FunkyInput
                                value={item.title}
                                label="SUBIECT"
                                onChange={inputHandler('title')}
                                disabled={disabled}
                            />
                        </Grid>


                    </Grid>
                </Grid>
            </Grid>

        </Boxy>
    )
}
