import { Tab, Tabs } from '@material-ui/core'
import React, {Fragment, useState} from 'react'
import Layout from '../../../components/Layout'
import Backup from './Backup'
import Restore from './Restore'

export default function BackupRestore() {

    const [tabIndex, setTabIndex ] = useState(0)

    const handleTabChange = (ev,value) => setTabIndex(value)
    return (
        <Fragment>
       <Tabs 
            value={tabIndex}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            orientation="horizontal"
            variant="scrollable"
            scrollButtons="auto"
       >
           <Tab value = {0} label = "BACKUP"/>
           <Tab value = {1} label = "RESTAURARE"/>
        </Tabs>
        {tabIndex  === 0 && <Backup/>}
        {tabIndex  === 1 && <Restore/>}
        </Fragment>
    )
}
