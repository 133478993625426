import React, {useContext, useEffect} from 'react'
import { Context } from '../utils/Store'
import ls from '../utils/ls'

const Logout = props => {

    const [state, dispatch] = useContext(Context)

    useEffect(() => {
        ls.remove()
        dispatch({
            isLoggedIn: false, 
            data: {}
        })
        props.history.push('/login')
        
    }, [])

    return (<div>Logging out...</div>)
}

export default Logout