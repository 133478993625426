import React, { useContext, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Main from './Main'
import  {Context} from "./utils/Store"
import { createMuiTheme, ThemeProvider } from "@material-ui/core"

import storage from './lib/storage'
import config from './config'
import ls from './utils/ls'
import Login from './routes/Login';
import ApolloClientProvider from './apollo/ApolloClient'
import { FaCcVisa } from 'react-icons/fa';

function App() {
  const [state, dispatch] = useContext(Context);
  const darkTheme = createMuiTheme({
    palette: {type: "light"}
  })

  useEffect(() => {
      let tk  = ls.get()
      tk = tk && tk.token
    dispatch({token:tk})
  }, [state.user])
  
  return (
  <ApolloClientProvider token = {state.token }>
    <div className="App">
        <ThemeProvider theme = {darkTheme}>
        <BrowserRouter>
          <Switch>
            <Route path = "/login"   component = {Login}/>
            <Route path = "/"   component = {Main}/>
          </Switch>
        </BrowserRouter>
        </ThemeProvider>
    </div>
    </ApolloClientProvider>
  );
}

export default App;
