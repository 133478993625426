import  { gql } from '@apollo/client'

export const QUERY_COMPANY = gql`
  query Company($id: ID!) {
      company(id: $id ) { 
        id
        entries { 
            id nr dt direction acl title files{ id name }
            doctype{  id label }
            contact{ id name surname }
            workflow {
              id title desc dt statusID status {title}
              # files { id path name }
              currentUser{id name}  
              status{ id title desc} }
              lastWorkflow { 
                id
                title          
                status{ id title desc}
                nextUser { id name }
              }
        }      
      }
    }

`

