import React, { useState, useEffect, useContext } from 'react'
import { Context } from '../utils/Store'
import ls from '../utils/ls'
import { useHistory } from 'react-router-dom'
import { Box, Paper, TextField, Typography, makeStyles, Button, Grid } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import config from "../config"
import fetch from 'isomorphic-unfetch'
import { FaUserFriends } from 'react-icons/fa'



const useStyles = makeStyles(theme => ({
    root: { flexGrow: 1 },
    text: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: 200,

        },
        '& .MuiInput-input': {
            fontSize: '4em'
        }
    }
}))

const Login = props => {
    const classes = useStyles()
    const [data, setData] = useState()
    const [state, dispatch] = useContext(Context)
    const [error, setError] = useState()
    const history = useHistory()

    useEffect(() =>{
        setData({username:"", password: ""})
    },[])
    useEffect(() => {
        // console.log("login effect!!! isLoggedIn:", state.isLoggedIn)
        if (state.isLoggedIn) history.push('/')
    }, [props])

    const inputHandler = name => ev => setData({ ...data, [name]: ev.target.value })

    const submitHandler = async () => {
        let res;
        try {
            res = await fetch( config.api + '/login', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({username: data.username, password: data.password })                
            })
            res = await res.json()
        } catch (error) {
            res = error
        } finally {
            
            if ( !res.token ) {
                console.log("no token")
                dispatch({error: res})
            } else {
                ls.append({...res, perPage: 10})
                dispatch({
                    user: res,
                    isLoggedIn: true

                })
                history.push('/')
                // console.log(res.token)
            }
        }

  
    }

    return (
        <React.Fragment>
            {/* {console.log("LOGIN COMPONENT isLoggedIn:", state.isLoggedIn)} */}
            {(!state.isLoggedIn && data) ? (
                <Box component="div" m={8}>
                    <FaUserFriends style={{ position: 'fixed', top: 50, left: '33%', fontSize: '10em', opacity: 0.07 }} />
                    <Paper style={{ height: '75vh', textAlign: 'center' }}>
                        <br />

                        <Typography variant="h2" color="primary" component="div"><strong>SCRIBUS</strong></Typography>
                        <Typography variant="h5" color="primary" component="h3"><strong>AUTENTIFICARE</strong></Typography>


                        <Grid container justify="center">
                            <Grid item xs={12} sm={10} md={8} lg={4} xl={4}>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    label="Nume utilizator"
                                    className={classes.text} 
                                    value={data.username } 
                                    onChange={inputHandler('username')} />
                            </Grid>
                        </Grid>

                        <br />
                        <br />
                        <Grid container justify="center">
                            <Grid item xs={12} sm={10} md={8} lg={4} xl={4}>
                                <TextField className={classes.text}
                                    name="parola"
                                    size="small"
                                    variant="outlined"
                                    label="Parola"
                                    onKeyDown={ev => {
                                        if (ev.key === 'Enter') submitHandler()
                                    }}
                                    type="password"
                                    value={data.password }
                                    fullWidth onChange={inputHandler('password')}
                                />
                            </Grid>
                        </Grid>
                        <br /><br />
                        <Grid container justify="center">
                            <Grid item xs= {10} sm = {4} lg = {4}>
                            <Button size="large" variant="contained" color="primary" onClick={submitHandler}>
                                <h3>AUTENTIFICARE</h3>
                            </Button>
                            <Grid item xs={10}>
                                <br/>
                            { state.error && <Alert>{JSON.stringify(state.error.err)}</Alert>}
                                    {/* {console.log("error:", state.error)} */}
                            </Grid>
                            </Grid>

                        </Grid>
                    </Paper>
                </Box>
            ) : <div>sunteti deja autentificat!</div>}
           

        </React.Fragment>


    )
}

export default Login