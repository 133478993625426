import React, { useState } from "react"
import { Grid, TextField, Button, makeStyles } from '@material-ui/core'
import FunkyInput from "../../components/FunkyInput"
import Autocomplete from '@material-ui/lab/Autocomplete';
import {gql} from 'apollo-boost'
import { useQuery } from '@apollo/client'
import { FaPlus, FaPlusCircle } from "react-icons/fa";
import {CONTACT_LOOKUP} from './_graphql'

const useStyles = makeStyles(theme => ({
    AutocompleteRoot: {padding: "0:important!"}
}))




const Contact = props => {
    const classes = useStyles()
    const [options, setOptions] = useState([])
    const [contact, setContact] = useState({name: ''})
    const [ text, setText ] = useState('')
    const {error, loading, data } = useQuery(CONTACT_LOOKUP, { variables: {text: text} })

   

    React.useEffect(() => {
         { if ( props.data) setOptions([{...props.data}]); setContact(props.data); }
    }, [])

    // ql effect
    React.useEffect(() => {
        if(data && data.contactLookup.length > 0 ) {
            setOptions(data.contactLookup)
        } 
    }, [data])
    
    const typingHandler = (ev,value)  => {   setText(value);  }
    
    const selectHandler = (ev,value)  => {
        ev.preventDefault()
        setContact(value || {})
        submitToParentComponent(value)
    }


    const submitToParentComponent = item => props.onChange(item ||{id:0}) 

    const changeOthers = name => ev => {
        setContact({...contact, [name]: ev.target.value})
        submitToParentComponent({...contact, [name]: ev.target.value})
    }

    

    return (
        
        
                <Autocomplete
                style = {{zIndex: '-1'}}
                disabled = {props.disabled}
                size = "small"
                id="autocomplete-combo"
                options={options}
                
                getOptionLabel={(option) => option.name || ''}
                onChange={(ev, val) => selectHandler(ev,val)}
                value={ contact }
                fullWidth
                onInputChange={(ev, newInputValue) =>typingHandler(ev, newInputValue) }
                // style={{ width: 300 }}
                renderInput={params => (
                    <FunkyInput 
                        {...params} 
                        variant="outlined" 
                        label = {props.label||""}
                        InputProps={{
                            ...params.InputProps,   
                          }}
                    />
                )}

                noOptionsText = {
                    <div align = "center">
                        <small style = {{color: "red"}}>persona nu există! </small><br/>
                    </div>
            }
            />
          

        
    )
}


export default Contact