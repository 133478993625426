import React from 'react'
import Layout from '../../components/Layout'
import CustomToolbar from "../../components/CustomToolbar"
import { Grid, makeStyles, Tabs, Tab, Paper } from '@material-ui/core'
import Tipdoc from './Tipdoc'
import Company from './Company'
import Misc from './Misc'
import Superadmin from './Superadmin'
import { Context } from '../../utils/Store'
import BackupRestore from "./BackupRestore"

const settings = [
    { label: "DATE COMPANIE" },
    { label: "TIPURI DOCUMENTE" },
    { label: "SETĂRI LOCALE" },
    { label: "BACKUP & RESTORE"}
]

const useStyles = makeStyles(theme => ({
    paper: { padding: theme.spacing(1), 
        margin: `${theme.spacing(3)} ${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(2)}`,
        width: '100%', height: '80vh', overflow: 'auto'}
}))

const Settings = props => {
    const [ctx, dispatch] = React.useContext(Context)
    const classes = useStyles()
    const [tabIndex, setTabIndex] = React.useState(1)

    const handleTabChange = (ev,value) => setTabIndex(value)
    return (
        <Layout>
            <CustomToolbar
                title="CONFIGURĂRI"
            />

            <Grid container spacing = {1}>
                <Grid item xs = {2}>
                <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    orientation="vertical"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {settings.map((e, i) => <Tab key={`li-${i}`} value = {i} label = {e.label} />) }
                    {ctx.user.role === "superadmin" && <Tab value = {99} label = "SUPER ADMIN" /> }
                </Tabs>
                </Grid>
                <Grid item xs ={10}>
                <Paper className = {classes.paper}>
                    {tabIndex === 0 && <Company/>}
                    {tabIndex === 1 && <Tipdoc/>}
                    {tabIndex === 2 && <Misc/>}
                    {tabIndex === 3  && <BackupRestore/>}
                    {tabIndex === 99 
                        && ctx.user.role === "superadmin" 
                        && <Superadmin/>}
                </Paper>
                </Grid>
                
            </Grid>
            
            
        </Layout>
    )
}

export default Settings