import React, { useEffect, useContext } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'
import { Context } from './utils/Store'
import ls from "./utils/ls"
import Login from './routes/Login'

import Logout from './routes/Logout'
import RouteNotFound from './routes/RouteNotFound'
import Registru from './routes/Registru'
import Landing from './routes/Landing'
import Agenda from './routes/Agenda'
import Setari from './routes/Setari'
// import  ApolloClient  from '@apollo/client'
import config from './config'
// import ApolloClientProvider from './apollo/ApolloClient'
import { InMemoryCache } from 'apollo-cache-inmemory';
import Document from './routes/Document'
import Nou from './routes/Document/Nou'
import Rapoarte from './routes/Rapoate'

const Main = props => {

    const history = useHistory()
    const [state, dispatch] = useContext(Context);
    // const cache = new InMemoryCache();

// create context from localstorage if user is already authed
    useEffect(() => {
       
        const storage = ls.get()
        if (!storage) {
            history.push('/login')
        } else {
            // console.log("st token: ", storage.token)
            setTimeout(() => dispatch({ 
                isLoggedIn: true, 
                user: storage,
                // client: new ApolloClient({
                //     cache: cache,
                //     uri: config.api +"/graphql",
                //     headers: {
                //         "authorization": "Bearer " + storage.token
                //     }
                // })
            }), 500)

        }
    }, [])

    return (
        <React.Fragment>

                {state&& state.user  && (
                //   <ApolloClientProvider token = {state.token}>
                    <Switch>
                    
                    <Route path = "/"  exact component = {Registru}/>
                    <Route path = "/registru" exact component = {Registru} />
                    <Route path = "/biroul-meu" exact component = {Landing} />
                    
                    <Route path = "/document/nou" component = {Nou} />
                    <Route path = "/document/:id" component = {Document} />
                    <Route path = "/agenda" component = {Agenda} />
                    {/* <Route path = "/contact/:id" component = {Contact} /> */}
                    <Route path = "/setari" component = {Setari} />
                    <Route path = "/rapoarte" component = {Rapoarte} />

                    <Route path = "/logout" exact component = {Logout} />
                    <Route path = "*" component = {RouteNotFound} />
                    </Switch>
                // </ApolloClientProvider>
                )}
               
            {/* </Switch> */}
           
        </React.Fragment>



    )
}

export default Main