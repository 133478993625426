import React from 'react'
import moment from 'moment'
import { Table, TableCell, TableHead, TableRow, makeStyles, TableBody } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {},
    table: {
        border: "1px solid black",
        "&& thead tr:nth-child(3) th": { padding: 0, fontSize: "0.5rem", fontWeight:"normal", textAlign: "center" },
        "&& thead>tr>th,td, tbody>tr>td": {
            padding: theme.spacing(0.5), border: "1px solid black", fontSize: "0.8rem"
        }
    }
}

))
export default function Registru(props) {
    const classes = useStyles()
    return (
        <div align="center" className={classes.root}>
            <h3>REGISTRU DE INTRĂRI-IEȘIRI</h3>

            <pre>în perioada {moment(props.start).format("DD.MM.YYYY")} - {moment(props.end).format("DD.MM.YYYY")} </pre>

            <table className={classes.table} cellPadding={0} cellSpacing={0}>
                <thead>
                    <tr>
                        <th rowSpan="2">Nr.de<br />înregistrare</th>
                        <th colSpan="2">Data înregistrării<br />Anul 20{moment(props.start).format('YY')}</th>
                        <th rowSpan="2">Nr.și data<br />documentului</th>
                        <th rowSpan="2">Emitent</th>
                        <th rowSpan="2">Conținutul pe scurt al<br />documentului</th>
                        <th rowSpan="2">Compartimentul și <br />semnătura <br />de primire</th>
                        <th colSpan="2">Date <br />expedierii<br />Anul 20{moment(props.start).format('YY')}</th>
                        <th rowSpan="2">Destinatar</th>
                        <th colSpan="2">Nr.de înregistrare se <br />conexează și indicativul<br />dosarului</th>
                        <th rowSpan="2">Observații</th>
                    </tr>
                    <tr>
                        <td>Ziua</td>
                        <td>Luna</td>
                        <td>Ziua</td>
                        <td>Luna</td>
                        <td>Nr.de<br />conex.</td>
                        <td>Ind.<br />dosar</td>
                    </tr>
                    <tr>
                        {[1,2,3,4,5,6,7,8,9,,10,11,12,13].map((e,i)=>(
                            <th key ={i}>{e}</th>
                        ))}
                    </tr>
                </thead>


                <tbody>
                    {props.entries && props.entries.map((e, i) => (
                        <TableRow key={i}>
                            <TableCell>{e.nr}</TableCell>
                            <TableCell>{moment(e.dt).format('DD')}</TableCell>
                            <TableCell>{moment(e.dt).format('MM')}</TableCell>
                            <TableCell>{e.nr + "/" +moment(e.dt).format('DD.MM.YYYY')}</TableCell>
                            <TableCell>
                                {e.direction === "in" 
                                    ? ((e.contact && e.contact.name) || "...")
                                    : e.user.name
                                }
                            </TableCell>
                            
                            <TableCell>{e.desc ? <div dangerouslySetInnerHTML = {{__html: e.desc}}/> : null}</TableCell>
                            <TableCell>---</TableCell>
                            <TableCell>
                                {e.direction === "out" ? moment(e.dt).format("DD"): "--"}
                            </TableCell>
                            <TableCell>
                                {e.direction === "out" ? moment(e.dt).format("MM"): "--"}
                            </TableCell>
                            <TableCell>
                                {e.direction === "out" 
                                    ? ((e.contact && e.contact.name) || "...")
                                    : e.lastWorkflow.nextUser.name
                                }
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>{e.doctype.label}</TableCell>
                        </TableRow>
                    ))}

                </tbody>
            </table>
            {/* {props.enTableRowies && (<pre>{JSON.sTableRowingify(props.enTableRowies, null, 3)}</pre>)} */}
        </div>
    )
}
