import React from 'react'
import FunkyInput from "../../components/FunkyInput"
import { QUERY_DOCTYPES } from './_graphql'
import { useQuery } from "@apollo/client"


export default function DocType (props) {
    const { data } = useQuery(QUERY_DOCTYPES)
    return (
        <>{data && data.doctypes &&  
        <FunkyInput 
            fullWidth = {true}
            select 
            disabled = {props.disabled}
            value = {props.doctype.id }
            onChange = {ev => props.onChange({
                id: ev.target.value,
                label: data.doctypes.filter( elem => elem.id === ev.target.value)[0]['label'],
                days: data.doctypes.filter( elem => elem.id === ev.target.value)[0]['days'],
            })}
            SelectProps={{
                native: true,
            }} >
            {data.doctypes.map( (e,i) =>(
                <option key = {`doctp-${i}`} value = {e.id}>{e.label}</option>
            ))}
        </FunkyInput>
        }</>
    )
}