import React from 'react'
import { Grid, Box, Paper, Typography, Button } from "@material-ui/core"
import { FaHome } from 'react-icons/fa'


export default (props) => (
    <Grid container style = {{height: "95vh"}} justify = "center" alignItems = "center">
        <Grid item xs = {10} sm = {4} lg = {3}>
        <Paper style = {{height: "50%", width: "100%", boxShadow: "2px 2px 10px lightgrey", borderRadius: "1em"}}>
                <br/>
                <Typography variant = "caption" component = "p" align = "center">
                    eroare
                </Typography>
                <Typography variant = "h1" component = "p" align = "center">
                    404
                </Typography>
                <Typography variant = "caption" component = "p" align = "center">
                    pagină inexistentă!
                </Typography>
                <br/>
                <div align = "center">
                <Button variant = "outlined" onClick = {() => props.history.push("/")} style = {{width: "40%"}}>
                   <div>
                   <FaHome style = {{fontSize: '4em'}}/>
                   <br/>
                   SCRIBUS
                   </div>
                 
                   
                    
                </Button>
                </div>
                <br/>
            </Paper>
        </Grid>

        
    </Grid>
) 