import React from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { QUERY_TIPDOC, MUTATE_TIPDOC, REMOVE_TIPDOC } from './_graphql'
import { makeStyles, Grid, TextField, Typography, Toolbar, Button } from '@material-ui/core'
import { Context } from '../../utils/Store'
import { FaCheck, FaTimes } from 'react-icons/fa'

const useStyles = makeStyles(t => ({
    toolbar: { background: t.palette.grey[200]}
}))

const Input = props => (
    <TextField
        variant =  "outlined" fullWidth = {true} size = "small"
        value = {props.value || ''}
        label = {props.label || ''}
        placeholder = {props.placeholder}
        {...props}
    />
)

export default function Tipdoc(props) {

    const classes = useStyles()
    const [context, dispatch] = React.useContext(Context)
    const [ item, setItem ] = React.useState()

    const {data, loading, error} = useQuery(QUERY_TIPDOC)
    const [ updateRow, dispatchRow ] = useMutation(MUTATE_TIPDOC)
    const [ removeRow, dispatchRemoveRow ] = useMutation(REMOVE_TIPDOC)
    const bottom = React.useRef()

    React.useEffect(() => {
        if ( data && data.doctypes) setItem(data.doctypes)
    }, [data])

    const changeHandler = (index, label) => ev => {
        console.log("i:", index, "l:", label)
        let ni = [...item]
        let value = ev.target.value
        if ( value && label === "days" ) { value = parseInt(value) }
        ni[index][label] = value
        ni[index]['touched'] = true
        setItem(ni)
    }

    const addRowHandler = () => {
        setItem( [...item, {label: '', desc: '', days: 30, companyID: context.user.companyID}])
        bottom.current.scrollIntoView()
    }

    const updateHandler = (i,e) => () => {
        updateRow({variables: e}).then( 
            r => {
                
                let ni = [...item]
                ni[i] = {...ni[i], ...r.data.doctype};
                delete(ni[i]['touched'])
                setItem(ni)
                
            },
            err => {
                console.log("update err:", err)
            }
        )
    }

    const removeHandler = (i, id) => () => {
        console.log("remove params: ", i, id)
        removeRow({variables: {id:id}}).then( 
            r => {
                if ( r.data.removeDoctype === true ){
                    let ni = [...item]
                    ni.splice(i, 1)
                    setItem(ni)
                    
                }
            },
            err => console.log("err:", err)
        )
    }

    return (
        <div>
            {loading && <div>SE INCARCA...</div>}
            {error && <pre>{JSON.stringify(error,null,4)}</pre>}
            {item && (
                <React.Fragment>
                    <Toolbar variant = "dense" className = {classes.toolbar} >
                        <Grid container justify = "space-between" alignItems = "center">
                        <Typography variant = "h6" color = "primary">TIPURI DE DOCUMENTE</Typography>
                        {/* {item && item.length === 0  ? "x" : "y"} */}
                        <Button color = "primary" onClick = {addRowHandler}>ADAUGA</Button>
                        </Grid>
                    </Toolbar>

                    <hr/>
                    <Grid container>
                        <Grid item sm = {2} style = {{paddingLeft:'10px', paddingBottom:'10px'}}><strong>DENUMIRE</strong></Grid>
                        <Grid item sm = {6} style = {{paddingLeft:'10px', paddingBottom:'10px'}}><strong>DESCRIERE</strong></Grid>
                        <Grid item sm = {2} style = {{paddingLeft:'10px', paddingBottom:'10px'}}><strong>SCADENȚĂ</strong></Grid>
                    </Grid>
                    <div style = {{width: '100%', height: '55vh', overflow: 'auto'}}>
                    {item.map( (e,i) => (
                    <Grid container key = {`doctp-${i}`} style = {{width: '100%'}} spacing = {1} alignItems = "center"> 
                        {/* {JSON.stringify(e)} */}
                        <Grid item>{i < 9 ? "0"+(i+1) : i+1}</Grid>
                        <Grid item sm = {2} align = "left">
                            <Input value = {e.label || ''} onChange = {changeHandler(i, 'label')} />
                        </Grid>
                        <Grid item sm = {6} align = "left">
                            <Input value = {e.desc || ''}  onChange = {changeHandler(i, 'desc')} />
                        </Grid> 
                        <Grid item sm = {2} align = "right">
                        <Input value = {e.days || 0} type = "number" onChange = {changeHandler(i, 'days')} />
                        </Grid>   
                        
                        <Button 
                            variant = "text" color = "secondary" 
                             disabled = {e.entries && e.entries.length > 0 }
                             onClick = {removeHandler(i, e.id)}
                        >
                            <FaTimes />
                        </Button>
                        {   e.label 
                            && e.touched
                            && <Button onClick = {updateHandler(i,e)}>
                                    <FaCheck style ={{color: "blue"}}/>
                                </Button>
                        } 
                        
                        
                        
                    </Grid>
            ))}
                <div ref = {bottom}></div>
            </div>
                </React.Fragment> 
            )}
            
                
        </div>
    )
}
