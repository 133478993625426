import React from 'react'
import { makeStyles, Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid } from '@material-ui/core'
import FunkyInput from '../../components/FunkyInput'
import { Context } from '../../utils/Store'

const useStyles = makeStyles(t => ({
    root: { flexGrow: 1 },
    dialogPaper: { height: "50vh", width: "75vw"}
}))

const fields = [
    { name: 'name', label: "nume și prenume", xs: 12, sm: 12 },
    { name: 'username', label: "nume utilizator", xs: 6, sm: 6 },
    { name: 'password', label: "parolă", xs: 6, sm: 6 },
    { name: 'email', label: "email", xs: 6, sm: 6 },
]

const AddUserModal = (props) => {

    const [ item, setItem ] = React.useState()
    const ctx = React.useContext(Context)

    React.useEffect(() => {
        if ( props && props.data ) { setItem(props.data) } else { setItem() }
    }, [props])

    const inputHandler = name => ev => setItem({...item, [name]: ev.target.value})
    const roleHandler = ev => setItem({...item , role: ev.target.value})

    const closeHandler = data => () => { props.onClose(data); setItem();}

    const classes = useStyles()
    return (
        <React.Fragment>
            {/* { console.log("modal:", item)} */}
            <Dialog 
                open = {Boolean(item)}
                onClose = {closeHandler()}
                classes = {{paper: classes.dialogPaper}}
            >
                <DialogTitle>
                    {item && item.id ? "EDITARE UTILIZATOR" : "ADĂUGARE UTILIZATOR"}
                </DialogTitle>         
                <DialogContent>
                    <Grid container spacing = {2}>
                    {item && fields.map( (e,i) => (
                        <Grid item xs = {e.xs} sm = {e.sm} key = {i}>
                            <FunkyInput label = {e.label} value = {item[e.name]} onChange = {inputHandler(e.name)}/>
                        </Grid>
                    ))}
                    <Grid item xs = {6} sm = {6}>
                        <FunkyInput select value = {item && item.role || null} SelectProps={{ native: true}} onChange = {roleHandler}>
                            <option value = "">alegeti un rol (obligatoriu)</option>
                            <option value = "user">user</option>
                            <option value = "admin">admin</option>
                            {item && item.role === 'superadmin' &&  <option value = "superadmin">superadmin</option>}
                        </FunkyInput>
                    </Grid>
                   
                    </Grid>
                </DialogContent>   
                <DialogActions>
                    <Button variant = "contained" color = "secondary" onClick = {closeHandler() }>
                        RENUNȚĂ
                    </Button>
                    <Button variant = "contained" color = "primary" 
                        disabled = {!(item && item.name && item.username && item.role)}
                        onClick = {closeHandler(item) }>
                        CONFIRMĂ
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default AddUserModal
